// 动态注册全局指令
const modulesFiles = require.context('./', true, /\.js$/)
export function useDirective(app) {
  modulesFiles.keys().forEach(item => {
    const moduleName = item.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(item)
    if (value.default) {
      const [name] = moduleName.split('/')
      app.directive(name, value.default)
    }
  })
}
