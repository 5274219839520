import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import App from './App.vue'
import { useVant } from '@/vant'

import 'normalize.css/normalize.css'
import '@/styles/index.scss'
import './icons'

import { Locale } from 'vant'
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US'

import '@/utils/message'
// rem
import useRem from '@/utils/rem.js'
// 全局组件
import { useComponent } from '@/components/common'
// 全局指令
import { useDirective } from '@/directive'
import './permission'

Locale.use('en-US', enUS)

const pinia = createPinia()
const app = createApp(App)
// 注册全局elementPlus
useVant(app)
useRem()
useComponent(app)
useDirective(app)

app.use(router).use(pinia).mount('#app')
