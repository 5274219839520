import { Notify } from 'vant'

export const copyText = (value) => {
  const textarea = document.createElement('textarea')
  // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
  textarea.readOnly = 'readonly'
  textarea.style.position = 'absolute'
  textarea.style.left = '-9999px'
  textarea.value = value
  document.body.appendChild(textarea)
  textarea.select()
  const result = document.execCommand('Copy')
  if (result) {
    Notify({
      type: 'success',
      message: 'Copy success',
      duration: 1500
    })
  }
  document.body.removeChild(textarea)
}
const vCopy = {
  mounted(el, { value }) {
    el.$value = value
    el.handler = () => {
      if (!el.$value) {
        // alert('无复制内容')
        Notify({
          type: 'danger',
          message: 'Copy failed',
          duration: 1500
        })
        return
      }
      copyText(el.$value)
    }
    el.addEventListener('click', el.handler)
  },
  // 当传进来的值更新的时候触发
  updated(el, { value }) {
    el.$value = value
  },
  unmounted(el) {
    el.removeEventListener('click', el.handler)
  }
}

export default vCopy
