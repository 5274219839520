const jpeg = { type: 'image/jpeg', suffix: 'jpeg' }
const jpg = { type: 'image/jpg', suffix: 'jpg' }
const png = { type: 'image/png', suffix: 'png' }
const bmp = { type: 'image/bmp', suffix: 'bmp' }

export const phoneFileType = [jpeg, jpg, png, bmp]

export const photoConfig = {
  PhotoPage: {
    // 护照
    img: require('@/assets/sample/PhotoPage.jpg'),
    uploadText: 'Upload the Photo Page of Passport'
  },
  ResidentPermit: {
    // 居留证
    img: require('@/assets/sample/ResidentPermit.png'),
    uploadText: 'Upload the Residence Permit of Passport'
  },
  CGC_FrontPage: {
    // 永居证（中国绿卡）正
    img: require('@/assets/sample/CGC_FrontPage.png'),
    uploadText: 'Upload the Foreign Permanent Resident ID Card (Front Side)'
  },
  CGC_BackPage: {
    // 永居证（中国绿卡）反
    img: require('@/assets/sample/CGC_BackPage.png'),
    uploadText: 'Upload the Foreign Permanent Resident ID Card (Back Side)'
  },
  HK_FrontPage: {
    // 港澳回乡证 正
    img: require('@/assets/sample/HK_FrontPage.png'),
    uploadText:
      'Upload the Mainland Travel Permit for Hong Kong and Macao Residents (Front Side)'
  },
  HK_BackPage: {
    // 港澳回乡证 反
    img: require('@/assets/sample/HK_BackPage.png'),
    uploadText:
      'Upload the Mainland Travel Permit for Hong Kong and Macao Residents (Back Side)'
  },
  TW_FrontPage: {
    // 台胞证 正
    img: require('@/assets/sample/TW_FrontPage.png'),
    uploadText:
      'Upload the Mainland travel permit for Taiwan residents (Front Side)'
  },
  TW_BackPage: {
    // 台胞证 反
    img: require('@/assets/sample/TW_BackPage.png'),
    uploadText:
      'Upload the Mainland travel permit for Taiwan residents (Back Side)'
  },
  income_proof: {
    // 工资证明
    img: require('@/assets/sample/income_proof.jpg'),
    uploadText: 'Upload Income Proof'
  },
  Payslip: {
    // 工资单
    img: require('@/assets/sample/Payslip.png'),
    uploadText: 'Upload Income Proof'
  },
  tax_detail_page: {
    // 税务详情页
    img: [
      require('@/assets/sample/tax_detail_page_1.png'),
      require('@/assets/sample/tax_detail_page_2.png')
    ],
    uploadText: 'Upload Tax Detail Page',
    // linkText:'How to get the Tax Documents',
    link: 'https://mp.weixin.qq.com/s?__biz=Mzk0NzQxOTQ2MA==&mid=2247484004&idx=1&sn=e0e12923b2c977f99fdb94f46373892b&chksm=c376624ff401eb598830056b6cc6c2dd160a83e2138c1032abdbf8d4522b202350af2a6bd70e&mpshare=1&scene=1&srcid=0616hz5v8LRDanyfJnvyZahH&sharer_sharetime=1686890127101&sharer_shareid=1184fb457296844f0219452db87604a7&from=industrynews&version=4.1.9.6012&platform=win#rd'
  },
  tax_records: {
    // 纳税申报单
    img: require('@/assets/sample/tax_records.png'),
    uploadText: 'Upload Tax Records',
    // linkText:'How to get the Tax Documents',
    link: 'https://mp.weixin.qq.com/s?__biz=Mzk0NzQxOTQ2MA==&mid=2247484004&idx=1&sn=e0e12923b2c977f99fdb94f46373892b&chksm=c376624ff401eb598830056b6cc6c2dd160a83e2138c1032abdbf8d4522b202350af2a6bd70e&mpshare=1&scene=1&srcid=0616hz5v8LRDanyfJnvyZahH&sharer_sharetime=1686890127101&sharer_shareid=1184fb457296844f0219452db87604a7&from=industrynews&version=4.1.9.6012&platform=win#rd'
  },
  labor_contract: {
    // 劳动合同
    img: [
      require('@/assets/sample/tax_records_1.png'),
      require('@/assets/sample/tax_records_2.png'),
      require('@/assets/sample/tax_records_3.png')
    ]
  },
  bankIncomeStatement: {
    img: [
      require('@/assets/sample/bankIncomeStatement1.png'),
      require('@/assets/sample/bankIncomeStatement2.png'),
      require('@/assets/sample/bankIncomeStatement3.png')
    ]
  }
}
