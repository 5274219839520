<template>
  <van-field
    :model-value="maxCount > 1 ? modelValue.join() : modelValue"
    :rules="rules"
    :name="name"
    class="custom-field app-upload"
  >
    <template #label>
      <div v-if="label || template" class="mb20 flex-sb-c">
        <div class="label">
          {{ label }}
        </div>
        <div v-if="template" class="template">
          <AppButton
            type="text"
            class="f24 button"
            @click="hanldeTemplate"
          >template</AppButton>
        </div>
      </div>
    </template>
    <template #input>
      <AppAlert
        v-if="alertAttrs.length"
        :config="alertAttrs"
        class="mb30"
      />
      <div v-if="isShowTips" class="tips f20">Make sure clear and complete image uploads.</div>
      <button
        type="button"
        class="upload-button w500 flex-c"
        :disabled="disabled"
        @click="handleUpload"
      >
        <svg-icon icon-class="upload" class="icon" />
        <span class="text">{{ buttonText }}</span>
      </button>
      <van-uploader
        ref="uploader"
        v-model="fileList"
        v-bind="$attrs"
        :max-size="maxSize"
        image-fit="contain"
        :max-count="maxCount"
        :preview-full-image="false"
        :after-read="handleAfter"
        @delete="handleDelete"
        @click-preview="hanldePreview"
        @oversize="onOversize"
      />
    </template>
    <template v-if="errorSlotName" #error-message>
      <slot :name="errorSlotName" />
    </template>
  </van-field>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'
import { Notify, ImagePreview } from 'vant'
import { formatBytes } from '@/utils'
import { phoneFileType, photoConfig } from './config'
import { uploadRules } from '@/utils/rules'
import { upload, getUpload } from '@api/base'
const props = defineProps({
  modelValue: [String, Array],
  uploadButtonText: String,
  photoType: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  rules: {
    type: Array,
    default: () => uploadRules
  },
  maxSize: { // 4M
    type: Number,
    default: 4 * 1024 * 1024
  },
  maxCount: {
    type: Number,
    default: 1
  },
  fileType: {
    type: Array,
    default: () => phoneFileType
  },
  name: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isShowTips: {
    type: Boolean,
    default: false
  },
  alertAttrs: {
    type: Array,
    default: () => []
  },
  errorSlotName: {
    type: String,
    default: ''
  }
})

const uploader = ref(null)

function handleUpload() {
  uploader.value.chooseFile()
}

const emit = defineEmits(['update:modelValue'])
function emitInput (val) {
  emit('update:modelValue', val)
}

const fileList = ref([])
const loading = ref(false)
function getFile() {
  loading.value = true
  const params = {
    fileId: props.modelValue
  }
  getUpload(params).then(res => {
    // console.log('获取文件', res)
    const { content } = res
    fileList.value = content.map(item => ({
      url: item.fileUrl,
      name: item.fileName,
      fileId: item.fileId,
      status: 'done'
    }))
    loading.value = false
  }).catch(() => {
    loading.value = false
  })
}
const buttonText = computed(() => photoConfig[props.photoType]?.uploadText || props.uploadButtonText)
const template = computed(() => photoConfig[props.photoType]?.img)

const haveUrl = computed(() => (props.maxCount > 1 && props.modelValue.length) || (+props.maxCount === 1 && props.modelValue))
watchEffect(() => {
  if (haveUrl.value) {
    getFile()
  }
})
function onOversize() {
  Notify(`The file size cannot exceed ${formatBytes(props.maxSize)}`)
}
// 上传前处理
function handleAfter(data) {
  const { type } = data.file
  const allowType = props.fileType.some(item => item.type === type)
  if (!allowType) {
    const typeSuffix = props.fileType.map(item => item.suffix)
    Notify(`We do not support this file type. Supported file types include${typeSuffix.join('、')}。`)
    fileList.value = []
    return false
  }
  const imgFile = new FormData()
  imgFile.append('file', data.file)
  data.status = 'uploading'
  data.message = 'loading...'
  loading.value = true
  upload(imgFile).then(res => {
    // console.log('文件上传回调', res)
    const { fileId } = res
    if (props.maxCount > 1) {
      const list = [...props.modelValue, fileId]
      emitInput(list)
    } else {
      emitInput(fileId)
    }
    loading.value = false
  }).catch(() => {
    loading.value = false
    data.status = 'failed'
    data.message = 'failed'
  })
}
// 删除
function handleDelete(file) {
  if (props.maxCount > 1) {
    const { fileId } = file
    const list = [...props.modelValue]
    const index = list.findIndex(v => v === fileId)
    list.splice(index, 1)
    emitInput(list)
  } else {
    emitInput()
  }
}
// 预览
function hanldePreview(file) {
  const { url } = file
  ImagePreview([url])
}
// 预览
function hanldeTemplate() {
  if (Array.isArray(template.value)) {
    ImagePreview(template.value)
  } else {
    ImagePreview([template.value])
  }
}
</script>

<script>
export default { name: 'AppUpload' }
</script>

<style scoped lang='scss'>
.app-upload{
  display: block;
  .label{
    margin-bottom: 0;
  }
  .button{
    text-decoration: underline;
  }
}
.tips{
  line-height: .32rem;
  color: $color99;
  font-family: 'Arial-Regular';
}
.upload-button{
  width: 100%;
  background: #DCEDF6;
  font-size: .28rem;
  font-weight: 500;
  color: $color33;
  border-radius: .2rem;
  padding: .3rem .6rem;
  .icon{
    font-size: .5rem;
    color: $blue;
  }
  .text{
    flex: 1;
    text-align: center;
  }
  &:disabled{
    .icon{
      color: $colorCc;
    }
    color: $colorCc;
  }
}
</style>
<style lang="scss">
.app-upload{
  .van-field__control{
    display: block;
  }
  .van-uploader{
    display: block;
  }
  .van-uploader__preview{
    flex-shrink: 0;
    width: 1.1rem;
    height: 1.1rem;
    margin: .36rem .2rem 0 0;
    border-radius: .2rem;
    .van-image{
      width: 100%;
      height: 100%;
    }
  }
  .van-uploader__upload{
    display: none;
  }
}
.van-image-preview{
  .van-image-preview__image{
    padding: 0 .8rem;
  }
}
</style>
