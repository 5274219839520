import { emailReg, phoneReg } from './reg'

/**
 * @description: 字节大小转换
 * @param {Number} bytes 字节
 * @return {Sting} 格式化字节
 */
export function formatBytes(bytes) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat(bytes / Math.pow(k, i)) + '' + sizes[i]
}

/**
 * @description: 格式化金额
 * @param {string} s [金额]
 * @param {number} n [保留小数位]
 * @return {string}
 */
export const formatMoney = function (s, cur, n = 2) {
  // const blackList = ['IDR', 'ILS']
  // if (blackList.includes(cur)) {
  //   n = 0
  // }
  if (!s) return '0.00'
  let minus = ''
  s = s.toString()
  if (s < 0) {
    s = s.replace(/\-/, '')
    minus = '-'
  }
  s = parseFloat((s + '').replace(/[^\d\.]/g, '')).toFixed(n) + ''
  const l = s.split('.')[0].split('').reverse()
  const r = s.split('.')[1]
  let t = ''
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '')
  }
  return `${minus}${t.split('').reverse().join('')}${n > 0 ? '.' + r : ''}`
}
/**
 * @description: Date safari兼容
 * @param {Date} date
 * @return {Date}
 */
export function safariDate(date) {
  if (!date) return
  const _date = date.toString().replace(/-/g, '/')
  return new Date(_date)
}
/**
 * @description: 解析date
 * @param {object | string | number} time
 * @param {string} cFormat [时间格式]
 * @return {Date}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds()
  }
  return format.replace(/{(y|m|d|h|i|s)+}/g, (result, key) => {
    let value = formatObj[key]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
}

// 是否是邮箱
export function isEamil(s) {
  return emailReg.test(s)
}
// 是否是手机
export function isPhone(s) {
  return phoneReg.test(s)
}
// 银行卡号脱敏
export function formatBankNumDese(v) {
  if (!v) return
  return v.replace(/^(.{4}).*(.{4})$/g, '$1 **** **** $2')
}
export function hideMiddleDigits(account) {
  account = account + ''
  if (account.length >= 1 && account.length <= 4) {
    return account // 不隐藏
  } else if (account.length >= 5 && account.length <= 6) {
    return (
      account.substring(0, 2) +
      '*'.repeat(account.length - 4) +
      account.substring(account.length - 2)
    )
  } else if (account.length >= 7 && account.length <= 8) {
    return (
      account.substring(0, 3) +
      '*'.repeat(account.length - 6) +
      account.substring(account.length - 3)
    )
  } else if (account.length >= 9) {
    return (
      account.substring(0, 4) +
      '*'.repeat(account.length - 8) +
      account.substring(account.length - 4)
    )
  }
}
// 银行卡号格式化
export function formatBankNum(v) {
  if (!v) return
  return v.replace(/(.{4})(?=\d)/g, '$1' + ' ')
}
// 手机号脱敏
export function formatPhone(v) {
  if (!v) return
  return v.replace(/(\d{3})\d*(\d{4})/g, '$1****$2')
}

// 格式化字符串金额
export function formatAmt(v) {
  if (!v) return 0
  v = v.toString()
  return +v.replace(/[^\d\.]/g, '')
}

/**
 * 防抖函数
 * @param {Function} fn 延迟函数
 * @param {Number} delay 延迟时间
 */
export function debounce(fn, delay = 500) {
  // 记录上一次的延时器
  let timer = null
  return function () {
    // 清除上一次延时器
    clearTimeout(timer)
    timer = setTimeout(fn, delay)
  }
}
// 币种对拆分
export function currencyPairFormat(s) {
  if (!s) return
  return { start: s.slice(0, 3), end: s.slice(-3) }
}
// 是否是微信端
export function isWX() {
  return !!(
    /micromessenger/.test(navigator.userAgent.toLowerCase()) ||
    typeof WeixinJSBridge !== 'undefined'
  )
}
const countryList = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'RO',
  'SE',
  'SK',
  'PT',
  'SI',
  'IS',
  'LI',
  'NO',
  'CH',
  'AD',
  'MC',
  'SM',
  'VA',
  'GB'
]
/**
 * 使用国际电汇
 * 条件1：币种JPY、NZD
 * 条件2：(非美国US、中国香港HK)地区下发USD
 * 条件3：非英国地区下发GBP
 * 条件4：非欧盟AT,BE,BG,CY,CZ,DE,DK,EE,ES,FI,FR,GR,HR,HU,IE,IT,LT,LU,LV,MT,NL,PL,RO,SE,SK,PT地区下发EUR
 * @param {String} currency 币种
 * @param {String} country 国家
 */
export function isUseSwift(currency, country) {
  if (!currency || !country) return
  const curList = ['JPY', 'NZD']

  const t1 = curList.includes(currency)
  const t2 = country !== 'US' && country !== 'HK' && currency === 'USD'
  const t3 = country !== 'GB' && country !== 'IM' && country !== 'GG' && country !== 'JE' && currency === 'GBP'
  const t4 = !countryList.includes(country) && currency === 'EUR'
  return t1 || t2 || t3 || t4
}
/**
 * 使用国际电汇下发文案
 * @param {String} currency 币种
 * @param {String} country 国家
 */
export function ttText(currency, country) {
  if (!currency || !country) return
  // 【USD下发非美国】文案
  if (country !== 'US' && currency === 'USD') {
    switch (country) {
      case 'CH': // 瑞士
        return 'Please note that when sending USD to a destination outside the United States, there may be an additional telegraphic fee of $15, along with intermediary bank and receiving bank fees typically ranging from $20 to $40, for which you will be responsible.'
      case 'TR': // 土耳其
      case 'CZ': // 捷克共和国
        return 'Please note that when sending USD to a destination outside the United States, there may be an additional telegraphic fee of $15, along with intermediary bank and receiving bank fees typically ranging from $40 to $65, for which you will be responsible.'
      case 'TN': // 突尼斯
        return 'Please note that when sending USD to a destination outside the United States, there may be an additional telegraphic fee of $15, along with intermediary bank fees typically ranging from $30 to $45, for which you will be responsible.'
      default:
        return 'Please note that when sending USD to a destination outside the United States, there may be an additional telegraphic fee of $15, along with intermediary bank and receiving bank fees typically ranging from $20 to $65, for which you will be responsible.'
    }
  }
  // GBP下发非英国】文案
  if (country !== 'GB' && country !== 'IM' && country !== 'GG' && country !== 'JE' && currency === 'GBP') {
    return 'Please note that when sending GBP to a destination outside the United Kingdom(UK;Great britain), there may be an additional telegraphic fee of $15, along with intermediary bank and receiving bank fees typically ranging from $20 to $65, for which you will be responsible.'
  }
  // 【EUR下发非欧盟成员国】文案
  if (!countryList.includes(country) && currency === 'EUR') {
    return 'Please note that when sending EUR to a destination outside the EU, there may be an additional telegraphic fee of $15, along with intermediary bank and receiving bank fees typically ranging from $20 to $65, for which you will be responsible.'
  }
  // 【JPY下发】文案
  if (currency === 'JPY') {
    return 'Please note that when sending JPY, there may be an additional telegraphic fee of $15, along with intermediary bank feestypically ranging from $20 to $65, for which you will be responsible.'
  }
  // 【NZD下发】文案
  if (currency === 'NZD') {
    return 'Please note that when sending NZD, there may be an additional telegraphic fee of $15, along with intermediary bank and receiving bank fees typically ranging from $20 to $65, for which you will be responsible.'
  }
  if (currency === 'KRW') {
    return 'Ensure your Korean phone number registered with the bank can receive verification SMS, or the transfer will fail.'
  }
}

// 入账时间
export function receivingTime(currency) {
  switch (currency) {
    case 'EUR':
    case 'AUD':
    case 'SGD':
    case 'MYR':
    case 'VND':
    case 'PKR':
    case 'BDT':
    case 'TRY':
    case 'PLN':
    case 'PHP':
    case 'MXN':
    case 'BRL':
    case 'PEN':
    case 'UYU':
    case 'NPR':
      return 'Within 24 hours.'
    case 'GBP':
    case 'HKD':
    case 'THB':
      return 'Within a few minutes.'
    case 'INR':
    case 'IDR':
    case 'NGN':
    case 'LKR':
      return 'Within a few hours.'
    default:
      return 'Within 24 to 48 hours.'
  }
}
