<template>
  <AppDialog
    v-model:visible="visible"
    :title="data.title"
    confirm-button-text="Got it"
    class="system-dialog"
    @close="close"
    @handle-confirm="close"
  >
    <div class="content">
      {{ data.content }}
    </div>
  </AppDialog>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
// import { remitDialog } from '@/utils/auth'
import Cookies from 'vue-cookies'
// import { useApp } from '@/store/useApp'
// const appStore = useApp()

const visible = ref(false)
const data = ref({})
const props = defineProps({
  systemNotice: {
    type: Object,
    default: () => ({})
  }
})
const emits = defineEmits(['closeModal'])
function close() {
  Cookies.set(props.systemNotice?.remitDialog, 'true', '1d')
  visible.value = false
  emits('closeModal')
}
function openModal() {
  if (!props.systemNotice) return
  data.value = props.systemNotice
  visible.value = true
}
watch(() => props.systemNotice, openModal)
onMounted(() => {
  openModal()
})
</script>

<script>
export default { name: 'AppSystemNotify' }
</script>

<style scoped lang='scss'>
.content {
  word-wrap: break-word;
}
</style>
<style lang='scss'>
.system-dialog {
  word-wrap: break-word;
}
</style>
