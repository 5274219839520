export const tabbarConfig = [
  {
    path: '/transaction',
    name: 'Transaction',
    title: 'Transactions',
    iconClass: 'transaction'
  },
  {
    path: '/send',
    name: 'Send',
    title: 'Send',
    iconClass: 'send'
  },
  {
    path: '/account',
    name: 'Account',
    title: 'Account',
    iconClass: 'account'
  }
]
