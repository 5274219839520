import Cookies from 'vue-cookies'

const key = 'skyee_remit_focus'

export function getFocus() {
  return Cookies.get(key)
}

export function setFocus() {
  return Cookies.set(key, 'TRUE', '1d')
}

export function removeFocus() {
  return Cookies.remove(key)
}
