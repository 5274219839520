<template>
  <div class="page-wrapper">
    <AppHeader :title="title" />
    <div class="page-main">
      <van-empty :description="description">
        <AppButton type="primary" @click="$router.push('/signUp/login')">Sign In</AppButton>
      </van-empty>
      <AppFooter />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: ''
  }
})
const descriptionEnum = {
  Recipients: 'No recipient yet.',
  Transaction: 'No transaction yet.'
}
const description = computed(() => descriptionEnum[props.title])
</script>

<script>
export default { name: 'AppEmpty' }
</script>

<style lang='scss' scoped>
.page-main{
  display: flex;
  justify-content: center;
}
</style>
<style lang='scss'>
.van-empty{
  width: 100%;
  padding: 1rem 0 $footerHeight !important;
  .van-empty__image{
    width: 3.2rem;
    height: 3.2rem;
  }
  .van-empty__description{
    font-size: .28rem;
    margin-top: .4rem;
    color: $color99;
  }
  .van-empty__bottom{
    width: 100%;
    margin-top: .4rem;
    padding: 0 $basePadding;
  }
}

</style>
