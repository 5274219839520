<template>
  <van-dialog
    :show="visible"
    :title="title"
    v-bind="$attrs"
    @open="handleOpen"
    @update:show="close"
  >
    <slot />
    <div class="close-wrapper" @click="close">
      <van-icon name="cross" />
    </div>
    <template #footer>
      <div class="dialog-footer">
        <van-button v-if="cancelButtonText" @click="handleCancel">{{ cancelButtonText }}</van-button>
        <van-button
          v-if="confirmButtonText"
          :loading="sLoading"
          :disabled="confirmButtonDisabled"
          @click="handleConfirm"
        >{{ confirmButtonText }}</van-button>
      </div>
    </template>
  </van-dialog>
</template>

<script setup>
defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: 'Notice'
  },
  cancelButtonText: {
    type: String,
    default: ''
  },
  confirmButtonText: {
    type: [String, Boolean],
    default: 'OK'
  },
  sLoading: {
    type: Boolean,
    default: false
  },
  confirmButtonDisabled: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:visible', 'handle-cancel', 'handle-confirm', 'handle-open'])
function handleCancel() {
  emit('handle-cancel')
  close()
}
function handleConfirm() {
  emit('handle-confirm')
}
function close() {
  emit('update:visible', false)
}
function handleOpen() {
  emit('handle-open')
}
</script>

<script>
export default { name: 'AppDialog' }
</script>

<style lang='scss'>
.van-dialog{
  width: 5rem;
  .van-dialog__header{
    padding: .3rem .38rem 0;
    color: $color33;
    font-size: .36rem;
    line-height: .42rem;
    font-family: 'Arial-Bold';
  }
  .van-dialog__content{
    padding: .2rem .28rem .4rem;
    color: $color66;
    font-size: .24rem;
    line-height: .28rem;
    text-align: center;
  }
  .dialog-footer{
    border-top: .01rem solid $colorEf;
    display: flex;
    button{
      flex: 1;
      height: .7rem;
      color: $blue;
      font-weight: 400;
      background: #fff;
      &+button{
        border-left: .01rem solid $colorEf !important;
      }
    }
  }
  .van-dialog__footer{
    &::after{
      border-color: $colorEf;
    }
    .van-button{
      height: .7rem;
      color: $blue;
      font-weight: 400;
    }
  }
  .close-wrapper{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    background: rgba(255,255,255,0.5);
    border-radius: 50%;
    width: .6rem;
    height: .6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .28rem;
  }
  .van-button--default{
    border: none !important;
  }
}
</style>
