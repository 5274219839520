<template>
  <van-field
    :model-value="modelValue"
    v-bind="$attrs"
    :readonly="readonly"
    :placeholder="placeholder"
    class="app-select input-select-icon"
    :class="{
      'is-disabled': disabled,
      'mini-select': isMiniSelect,
    }"
    @update:model-value="handleInput"
    @click-right-icon="handleIconShow"
    @click="handleShow"
  >
    <template v-if="(modelValue || showSlot) && readonly" #input>
      <slot>
        <div class="select-inner van-ellipsis">
          {{ showValue(modelValue) }}
        </div>
      </slot>
    </template>
    <template v-if="showRightIcon" #right-icon>
      <slot name="right-icon">
        <van-icon name="arrow" />
      </slot>
    </template>
  </van-field>
  <AppPopup
    v-model:visible="showPicker"
    :title="title"
    :is-search="isSearch"
    :is-fix="isFix"
    :close-on-click-overlay="isClose"
    @open="handleOpen"
  >
    <template v-if="isSearch" #search>
      <div class="search-input flex-c">
        <van-field
          v-model="searchValue"
          clearable
          placeholder="Search currency"
          @update:model-value="search"
        >
          <template #left-icon>
            <van-icon name="search" />
          </template>
        </van-field>
      </div>
    </template>
    <div class="picker-inner">
      <van-radio-group
        v-if=" _columns.length !== 0"
        :model-value="modelValue"
      >
        <van-cell
          v-for="item in _columns"
          :key="item.value"
          clickable
          @click="handleChange(item)"
        >
          <template #title>
            <slot name="inner" :prop="item">
              <div class="flex-c">
                <svg-icon
                  v-if="item.icon"
                  class="icon"
                  :icon-class="item.icon"
                />
                <div class="title f28">
                  {{ item.label }}
                </div>
                <div v-if="item.remark" class="remark f24 ml20">
                  {{ item.remark }}
                </div>
              </div>
            </slot>
          </template>
          <template #right-icon>
            <slot name="right-radio" :prop="item">
              <van-radio :name="item.value" />
            </slot>
            <slot name="right-icon-append" :prop="item" />
          </template>
        </van-cell>
      </van-radio-group>
      <div
        v-if="isSearch && _columns.length === 0"
        class="fs28"
        style="color: #ccc; padding-left: 0.5rem"
      >
        No Result
      </div>
      <slot name="popup-append" />
    </div>
  </AppPopup>
</template>

<script setup>
import { statusDistinguish } from '@/utils/enum'
import { computed, ref, toRefs } from 'vue'
import { getToken } from '@/utils/auth'
import { useRouter } from 'vue-router'
const router = useRouter()
const props = defineProps({
  title: String,
  placeholder: String,
  columns: {
    type: Array,
    default: () => []
  },
  isMiniSelect: {
    type: Boolean,
    default: false
  },
  showSlot: {
    type: Boolean,
    default: false
  },
  isSearch: {
    type: Boolean,
    default: false
  },
  isFix: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isClose: {
    type: Boolean,
    default: true
  },
  readonly: {
    type: Boolean,
    default: true
  },
  showRightIcon: {
    type: Boolean,
    default: true
  }
})

const modelValue = defineModel({ type: [Number, String] })

const emit = defineEmits(['update:model-value', 'change'])

const { columns } = toRefs(props)

const showPicker = ref(false)
function handleChange(item) {
  const { value, needUpdate } = item
  if (statusDistinguish(item.acctStatus, item.auditStatus, false).txt) {
    return
  }
  // 选择收款人
  if (needUpdate === 'Y') {
    return
  }
  modelValue.value = value
  showPicker.value = false
}

// 查找columns对应item
function findItem(val) {
  return props.columns.find(item => item.value === val) || {}
}
function handleInput(value) {
  emit('update:model-value', value)
}
function handleShow() {
  if (!getToken()) {
    router.push('/signUp/login')
    return
  }
  if (props.disabled || !props.readonly) return
  showPicker.value = true
}
function handleIconShow() {
  if (props.disabled || !props.columns.length) return
  showPicker.value = true
}
// 展示value
function showValue(val) {
  if (!val) return
  const item = findItem(val)
  return item.label
}
// 搜索
const searchValue = ref(null)
const list = ref([])
const _columns = computed(() => {
  if (!searchValue.value) {
    return columns.value
  } else if (list.value.length) {
    console.log(list.value)
    return list.value
  } else {
    return []
  }
})
function search() {
  const v = searchValue.value
  list.value = []
  for (let i = 0; i < props.columns.length; i++) {
    const label = (props.columns[i].label + '').toLocaleLowerCase()
    const remark = props.columns[i].remark ? (props.columns[i].remark + '').toLocaleLowerCase() : []
    const _v = (v + '').toLocaleLowerCase()
    if (label.includes(_v) || remark.includes(_v)) {
      list.value.push(props.columns[i])
    }
  }
}
function handleOpen() {
  list.value = []
  searchValue.value = undefined
}
</script>

<script>
export default { name: 'AppSelect' }
</script>

<style lang="scss" scoped>
.select-inner {
  padding: 0 0.36rem;
}
</style>
<style lang='scss'>
.app-select {
  background: transparent;
  &::after {
    display: none;
  }
  .van-cell__value {
    width: 100%;
  }
  &.van-cell.is-disabled {
    .van-field__body {
      border-color: #d9d9d9;
      background: #ececec !important;
    }
    .van-field__control {
      color: $color99 !important;
    }
  }
}
.search-input {
  padding: 0 $basePadding;
  .van-cell {
    padding: 0;
    border: 0.01rem solid $colorEf;
  }
  .van-field__left-icon {
    padding-left: 0.2rem;
    line-height: 0.84rem;
  }
  .van-field__body {
    height: 0.84rem;
    line-height: 0.84rem;
    font-size: 0.28rem;
    caret-color: $blue;
    .van-field__control {
      padding-left: 0.2rem;
    }
    .van-icon-clear {
      padding-left: 0;
      margin-right: 0.2rem;
    }
  }
}
</style>
<style lang="scss">
.picker-inner {
  .van-cell {
    padding: 0.3rem 0.4rem;
    .icon {
      width: 0.6rem;
      height: 0.6rem;
      margin-right: 0.22rem;
    }
    .title {
      color: $color33;
      line-height: 0.4rem;
    }
    .remark {
      color: #797979;
      line-height: 0.4rem;
    }
    .van-radio {
      margin-right: 0;
      .van-badge__wrapper {
        background: transparent !important;
        width: 0.36rem;
        height: 0.36rem;
        border-color: $color33;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          background: $color33;
          border-radius: 50%;
          transition: all 0.28s ease-in-out;
        }
      }
      .van-radio__icon--checked {
        .van-badge__wrapper {
          &::before {
            width: 0.24rem;
            height: 0.24rem;
          }
        }
      }
    }
  }
}
</style>
