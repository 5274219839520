<template>
  <van-field
    :model-value="modelValue"
    class="sms-input"
    @update:model-value="emitInput"
  >
    <template #button>
      <AppButton
        type="text"
        :loading="loading"
        @click="sendSms"
      >{{ countDownText }}</AppButton>
    </template>
  </van-field>
</template>

<script setup>
import { useCountDown } from '@vant/use'
import { computed, onBeforeUnmount, ref } from 'vue'
import Cookies from 'vue-cookies'
import { isEamil } from '@/utils'
import { SendMail, sms, loginSms } from '@api/base'
import { phoneReg, emailReg } from '@/utils/reg'
const TIME = 60
const props = defineProps({
  modelValue: String,
  smsCode: String,
  mailType: Number, // 场景类型【120:邮箱注册、122:重置密码通知、123:绑定邮箱、124:更换绑定邮箱,】
  smsType: [Number, String], // 验证码类型 101:绑定新手机、105:更换绑定手机
  countDownCookie: {
    type: String,
    default: ''
  },
  smsCodeType: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:modelValue'])
function emitInput (val) {
  emit('update:modelValue', val)
}
let count = Cookies.get(props.countDownCookie) > 0 ? Cookies.get(props.countDownCookie) : TIME
// 倒计时
const countDown = useCountDown({
  // 倒计时 60 秒
  time: count * 1000,
  onChange,
  onFinish
})

if (Cookies.get(props.countDownCookie) > 0) {
  countDown.start()
}
const { current } = countDown
const countDownText = computed(() => current.value.seconds > 0 && current.value.seconds !== TIME ? `${current.value.seconds}s` : 'Get Verification Code')
// 倒计时change监听
function onChange() {
  Cookies.set(props.countDownCookie, current.value.seconds, `${current.value.seconds}s`)
}
// 倒计时完成监听
function onFinish() {
  count = TIME
  countDown.reset()
}
const sendType = computed(() => isEamil(props.smsCode) ? 'E' : 'P') // 【P:手机｜E:邮箱】
const loading = ref(false)
// 发送验证码
function sendSms() {
  if (!phoneReg.test(props.smsCode) && !emailReg.test(props.smsCode)) {
    return
  }
  if (current.value.seconds > 0 && current.value.seconds !== TIME) return
  loading.value = true
  if (props.smsCodeType === 'Login') {
    const params = {
      userName: props.smsCode
    }
    loginSms(params).then(res => {
      // console.log('发送登录验证码', res)
      countDown.start()
      loading.value = false
    }).catch(() => {
      loading.value = false
    })
    return
  }
  if (sendType.value === 'E') {
    const params = {
      email: props.smsCode,
      mailType: props.mailType
    }
    SendMail(params).then(res => {
      // console.log('发送邮箱验证码', res)
      countDown.start()
      loading.value = false
    }).catch(() => {
      loading.value = false
    })
  } else {
    const params = {
      mobilePhone: props.smsCode,
      smsType: props.smsType
    }
    sms(params).then(res => {
    // console.log('发送手机验证码', res)
      countDown.start()
      loading.value = false
    }).catch(() => {
      loading.value = false
    })
  }
}
onBeforeUnmount(() => {
  // 停止计时器
  countDown.pause()
})
</script>

<script>
export default { name: 'AppSmsInput' }
</script>

<style lang='scss'>
.sms-input{
  .van-field__body{
    overflow: hidden;
  }
  .van-field__button{
    line-height: 1;
    .app-button{
      min-width: 1.6rem;
      text-align: center;
      border-radius: 0;
      padding: 0 .4rem 0 .32rem;
    }
  }
}
</style>
