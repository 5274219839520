import {
  Button,
  Form,
  Field,
  RadioGroup,
  Radio,
  Col,
  Row,
  Checkbox,
  CheckboxGroup,
  Picker,
  Popup,
  Uploader,
  Icon,
  Loading,
  List,
  Skeleton,
  Dialog,
  Collapse,
  CollapseItem,
  Empty,
  IndexBar,
  IndexAnchor,
  Cell,
  Badge,
  Cascader,
  Tabs,
  Tab,
  Popover,
  ActionSheet,
  Rate,
  SwipeCell,
  Sticky,
  Pagination,
  Overlay,
  Image
} from 'vant'

export const components = [
  Button,
  Form,
  Field,
  RadioGroup,
  Radio,
  Col,
  Row,
  Checkbox,
  CheckboxGroup,
  Picker,
  Popup,
  Uploader,
  Icon,
  Loading,
  List,
  Skeleton,
  Dialog.Component,
  Collapse,
  CollapseItem,
  Empty,
  IndexBar,
  IndexAnchor,
  Cell,
  Badge,
  Cascader,
  Tabs,
  Tab,
  Popover,
  ActionSheet,
  Rate,
  SwipeCell,
  Sticky,
  Pagination,
  Overlay,
  Image
]
