
export const basic = {
  input: {
    component: 'van-field',
    itemAttrs: {},
    attrs: {},
    rulesRrigger: 'onBlur'
  },
  'sms-input': { // 验证码输入框
    component: 'AppSmsInput',
    itemAttrs: {},
    attrs: {},
    rulesRrigger: 'onBlur'
  },
  'pwd-input': { // 密码输入框
    component: 'AppPasswordInput',
    itemAttrs: {},
    attrs: {},
    rulesRrigger: 'onBlur'
  },
  select: {
    component: 'AppSelect',
    itemAttrs: {},
    attrs: {},
    rulesRrigger: 'onChange'
  },
  cascader: { // 中国省市选择
    component: 'AppCascader',
    itemAttrs: {},
    attrs: {},
    rulesRrigger: 'onChange'
  },
  indexBar: {
    component: 'AppIndexBar',
    itemAttrs: {},
    attrs: {},
    rulesRrigger: 'onChange'
  },
  upload: { // 上传组件
    component: 'AppUpload',
    itemAttrs: {},
    attrs: {}
  },
  'money-input': { // 金额输入框
    component: 'AppMoneyInput',
    itemAttrs: {},
    attrs: {},
    rulesRrigger: 'onBlur'
  },
  radio: {
    component: 'AppRadio',
    itemAttrs: {},
    attrs: {},
    rulesRrigger: 'onChange'
  },
  checkbox: {
    component: 'AppCheckbox',
    itemAttrs: {},
    attrs: {},
    rulesRrigger: 'onChange'
  },
  searchBox: {
    component: 'AppSearchBox',
    itemAttrs: {},
    attrs: {},
    rulesRrigger: 'onChange'
  }
}
