<template>
  <div class="dib app-loading">
    <van-loading
      v-if="loading"
      v-bind="$attrs"
      :color="color"
    />
    <slot v-else />
  </div>
</template>

<script setup>
defineProps({
  loading: Boolean,
  color: {
    type: String,
    default: '#00A6FD'
  },
  size: {
    type: String,
    default: '.3rem'
  }
})
</script>

<script>
export default { name: 'AppLoading' }
</script>

<style lang='scss'>
.app-loading{
  .van-loading__spinner{
    width: v-bind(size);
    height: v-bind(size);
    font-size: v-bind(size);
  }
}
</style>
