import { emailReg, phoneReg } from './reg'
// blur校验规则
export function useBlurRules(prop = 'value') {
  return [
    {
      required: true,
      message: `Please enter a valid ${prop}`,
      trigger: 'onBlur'
    }
  ]
}
// change校验规则
export function useChangeRules(prop = 'value') {
  return [
    {
      required: true,
      message: `Please select ${prop}`,
      trigger: 'onChange'
    }
  ]
}

export const defaultBlur = [
  {
    required: true,
    message: 'required',
    trigger: 'onBlur'
  }
]
export const defaultChange = [
  {
    required: true,
    message: 'required',
    trigger: 'onChange'
  }
]
// 同意协议rules
export const confirmRules = [
  {
    validator: (value) => {
      if (!value) {
        return 'Please agree to the agreement'
      } else {
        return Promise.resolve()
      }
    },
    trigger: 'onChange'
  }
]

// 用户名rules
export const userNameRules = [
  {
    validator: (value) => {
      if (!value) {
        return 'Please enter the correct information.'
      } else if (!phoneReg.test(value) && !emailReg.test(value)) {
        return 'Please enter the correct information.'
      } else {
        return Promise.resolve()
      }
    },
    trigger: 'onBlur'
  }
]

// swiftCode验证
export function getSwiftCodeRules(code) {
  return [
    {
      validator: (value) => {
        const _code = value.slice(4, 6)
        if (!value) {
          return 'Please enter a valid SWIFT/BIC Code'
        } else if (
          (value.length !== 8 && value.length !== 11) ||
          _code !== code
        ) {
          return 'Please enter the correct SWIFT/BIC Code'
        } else {
          return Promise.resolve()
        }
      },
      trigger: 'onBlur'
    }
  ]
}

// acctEnName验证
export function getAcctEnNameRules() {
  const nonChineseRegex = /^[^\u4e00-\u9fa5]*$/
  return [
    {
      validator: (value) => {
        if (!value || !nonChineseRegex.test(value)) {
          return 'Please enter a valid Recipient Name'
        } else {
          return Promise.resolve()
        }
      },
      trigger: 'onBlur'
    }
  ]
}
// 银行卡rules
export const bankAccNumberRules = [
  {
    validator: (value) => {
      if (!/^\d{15,19}$/.test(value.replace(/\s+/g, ''))) {
        return 'The Chinese bank card number must be a valid China UnionPay card number.'
      } else {
        return Promise.resolve()
      }
    },
    trigger: 'onBlur'
  }
]
// 上传图片
export const uploadRules = [
  {
    validator: (value) => {
      if ((Array.isArray(value) && !value.length) || !value) {
        return 'required'
      } else {
        return Promise.resolve()
      }
    },
    trigger: 'onBlur'
  }
]
// 地址rules
export const addressRules = [
  {
    validator: (value) => {
      if (!value) {
        return 'Please enter a valid Residencial Address'
      } else if (value.length > 255 || value.length < 5) {
        return 'limits 5-255 characters'
      } else {
        return Promise.resolve()
      }
    },
    trigger: 'onBlur'
  }
]

// 邮箱
export const emailRules = [
  {
    validator: (value) => {
      if (!value) {
        return 'Please enter a valid Email Address'
      } else if (!emailReg.test(value)) {
        return 'Please enter the correct Email Address'
      } else {
        return Promise.resolve()
      }
    },
    trigger: 'onBlur'
  }
]
// 手机rules
export const mobileRules = [
  {
    validator: (value) => {
      if (!value) {
        return 'Your China Mobile Phone Number.'
      } else if (!phoneReg.test(value)) {
        return 'Please enter your China Mobile Phone Number.'
      } else {
        return Promise.resolve()
      }
    },
    trigger: 'onBlur'
  }
]
