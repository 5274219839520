<template>
  <van-field
    :rules="rules"
    class="custom-field my-checkbox"
  >
    <template #input>
      <van-checkbox-group
        :model-value="modelValue"
        direction="horizontal"
        :disabled="disabled"
        @update:model-value="handleChange"
      >
        <van-checkbox
          v-for="item in path"
          :key="item.value"
          shape="square"
          :name="item.label"
        >{{ item.label }}</van-checkbox>
      </van-checkbox-group>
    </template>
  </van-field>
</template>

<script setup>
import { defaultChange } from '@/utils/rules'
import { toRefs } from 'vue'
const porps = defineProps({
  rules: {
    type: Array,
    default: () => defaultChange
  },
  modelValue: {
    type: Array,
    default: () => []
  },
  path: {
    type: Array,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:model-value'])
const { modelValue } = toRefs(porps)

function handleChange (e) {
  emit('update:model-value', e)
}
</script>

<script>
export default { name: 'AppCheckbox' }
</script>

<style scoped lang='scss'>
.my-checkbox{
  padding: 0;
}
</style>
<style lang='scss'>
.van-checkbox-group{
  .van-checkbox{
    margin-right: .3rem;
  }
}
.van-checkbox{
  .van-checkbox__icon{
    font-size: .28rem;
    line-height: .36rem;
    margin-top: .04rem;
    .van-icon{
      width: .28rem;
      height: .28rem;
      line-height: .28rem;
      border-color: $color66;
    }
    &.van-checkbox__icon--checked{
      .van-icon{
        border-color: $blue;
        background: $blue;
      }
    }
  }
  .van-checkbox__label{
    margin-left: 0.10rem;
    font-size: .24rem;
    color: $color66;
    font-family: 'Arial-Regular';
    line-height: .36rem;
  }
}
</style>
