<template>
  <van-field
    :model-value="modelValue"
    v-bind="$attrs"
    autocomplete="off"
    :formatter="formatter"
    v-on="inputListeners"
  />
</template>

<script setup>
import { computed } from 'vue'
import { formatMoney } from '@/utils'

const props = defineProps({
  modelValue: [String, Number],
  decimal: {
    type: [String, Number],
    default: 2
  },
  reg: {
    type: RegExp,
    default: /[^\d\.]/g
  }
})
const emit = defineEmits(['update:model-value'])
// 格式化多余小数点
function fmoneyDot(s) {
  s = s.toString()
  return s.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
}
// input失去焦点
function handlerBlur(s) {
  if (!s) return
  return formatMoney(s, '', props.decimal)
}
// input聚焦
function handlerFocus(s) {
  if (!s) return
  return s.replace(props.reg, '')
}
function emitInput (val) {
  emit('update:model-value', val)
}
const inputListeners = computed(() => {
  return Object.assign(
    {},
    // 添加自定义监听器
    // 覆写监听器的行为
    {
      'update:model-value': function(val) {
        const v = val.replace(props.reg, '')
        emitInput(fmoneyDot(v))
      },
      blur: function(e) {
        const val = e.target.value
        emitInput(handlerBlur(val))
      },
      focus: function(e) {
        const val = e.target.value
        emitInput(handlerFocus(val))
      }
    }
  )
})
// 格式化输入内容
function formatter(val) {
  return fmoneyDot(val.replace(props.reg, ''))
}
</script>

<script>
export default { name: 'AppMoneyInput' }
</script>
