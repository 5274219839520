<template>
  <div
    class="float-service"
    :style="{
      'width':itemWidth+'px',
      'height':itemHeight+'px',
      'left':vm.left+'px',
      'bottom':vm.bottom
    }"
  >
    <a :href="serviceUrl">
      <svg-icon icon-class="contact-us" class="icon" />
    </a>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, reactive, ref } from 'vue'
const poops = defineProps({
  itemWidth: {
    type: Number,
    default: 40
  },
  itemHeight: {
    type: Number,
    default: 40
  },
  gapWidth: {
    type: Number,
    default: 10
  }
})
const serviceUrl = ref(process.env.VUE_APP_SERVICE_URL)
const vm = reactive({
  clientWidth: undefined,
  clientHeight: undefined,
  left: undefined,
  bottom: '1.6rem',
  timer: undefined,
  currentTop: undefined
})

vm.clientWidth = document.documentElement.clientWidth
vm.clientHeight = document.documentElement.clientHeight
vm.left = vm.clientWidth - poops.itemWidth - poops.gapWidth

function handleScrollEnd() {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  if (scrollTop === vm.currentTop) {
    if (vm.left > vm.clientWidth / 2) {
      vm.left = vm.clientWidth - poops.itemWidth - poops.gapWidth
    } else {
      vm.left = poops.gapWidth
    }
    clearTimeout(vm.timer)
  }
}

function handleScrollStart() {
  vm.timer && clearTimeout(vm.timer)
  vm.timer = setTimeout(() => {
    handleScrollEnd()
  }, 800)
  vm.currentTop = document.documentElement.scrollTop || document.body.scrollTop
  if (vm.left > vm.clientWidth / 2) {
    vm.left = vm.clientWidth - poops.itemWidth / 2
  } else {
    vm.left = -poops.itemWidth / 2
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScrollStart)
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScrollStart)
})
</script>

<script>
export default { name: 'AppFloatService' }
</script>

<style scoped lang='scss'>
.float-service{
  z-index: 999;
  transition: all 0.3s;
  position: fixed;
  a{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .82rem;
    color: #fff;
  }
}
</style>
