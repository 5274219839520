<template>
  <VanNotify
    v-model:show="show"
    class="app-notify"
    @click="handleClick"
  >
    <div class="content flex-c">
      <svg-icon class="icon" icon-class="messages" />
      <span class="text">
        {{ message }}
      </span>
    </div>
    <button class="close" @click.stop="show = false">
      <van-icon name="cross" />
    </button>
  </VanNotify>
</template>

<script setup>
import { Notify } from 'vant'
import { useUser } from '@/store/useUser'
import { computed, onMounted, ref } from 'vue'
import { getRemitOrder } from '@api/transaction'
import { useRouter } from 'vue-router'
const VanNotify = Notify.Component
const userStore = useUser()
const router = useRouter()

const orderPurchasing = computed(() => userStore._clientBasis.orderPurchasing)

const show = ref(false)

function getMessage(data) {
  const { remitStatus } = data
  switch (remitStatus) {
    case 'CREATED': // 已创建
    case 'WAIT_POST': // 待入账
    case 'WAIT_EDIT': // 待编辑
      return 'Transfer Incomplete.'
    case 'POSTED': // 已入账
      return 'Exchange rate confirmation pending.'
    case 'CONFIRMED': // 已确认汇率
      return 'Funds in transit.'
    case 'PAY_CLEAR_FAIL': // 下发失败
      return 'Funds Recieve Failed.'
  }
}

const detail = ref({})
const message = computed(() => getMessage(detail.value))
onMounted(() => {
  if (+orderPurchasing.value === 0) {
    const params = {
      page: 1,
      pageSize: 1,
      remitStatus: 'PROCESSING_OR_FAILED',
      orderBy: 1
    }
    getRemitOrder(params).then(res => {
      // console.log('汇款单列表', res)
      const { content, totalElements } = res
      if (totalElements > 0) {
        detail.value = content[0]
        show.value = true
      }
    })
  }
})

function handleClick() {
  show.value = false
  const { orderSign } = detail.value
  router.push({
    name: 'TransactionDetail',
    params: {
      id: orderSign
    }
  })
}

</script>

<script>
export default { name: 'AppNotifyRemit' }
</script>
