<template>
  <van-popup
    :show="visible"
    round
    :position="position"
    safe-area-inset-bottom
    v-bind="$attrs"
    teleport="body"
    class="my-popup"
    @close="close"
  >
    <div class="header flex-sb-c">
      <slot name="title">
        <span class="t w600 f26">{{ title }}</span>
      </slot>
      <button
        v-if="$attrs['close-on-click-overlay']"
        class="cancel"
        @click="cancel"
      >
        <van-icon name="cross" />
      </button>
    </div>
    <div v-if="isSearch" class="search-wrapper">
      <slot name="search" />
    </div>
    <div class="content" :class="{'is-search': isSearch, 'fixH': isFix }">
      <slot />
    </div>
  </van-popup>
</template>

<script setup>
import { toRefs } from 'vue'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  position: {
    type: String,
    default: 'bottom'
  },
  confirmText: {
    type: String,
    default: 'Select'
  },
  title: {
    type: String,
    default: 'Select'
  },
  isSearch: {
    type: Boolean,
    default: false
  },
  isFix: {
    type: Boolean,
    default: false
  }
})
const { visible } = toRefs(props)
const emit = defineEmits(['update:visible'])
function close() {
  emit('update:visible', false)
}
function cancel() {
  close()
}

</script>

<script>
export default { name: 'AppPopup' }
</script>

<style scoped lang='scss'>
.fixH {
  height: 58vh!important;
}
.header{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 .4rem;
  height: 1rem;
  z-index: 99;
  background: #fff;
  .cancel{
    font-size: .26rem;
    color: #111111;
  }
  .t{
    line-height: .42rem;
    color: $color33;
  }
  .select{
    flex-shrink: 0;
    background: #fff;
    border-radius: .4rem;
    padding: 0 .3rem;
    font-size: .28rem;
    border: 1px solid $blue;
    color: $blue;
    line-height: .56rem;
    height: .56rem;
    font-weight: 500;
  }
}
.search-wrapper{
  position: absolute;
  background: #fff;
  top: 1rem;
  left: 0;
  right: 0;
  height: 1rem;
  z-index: 99;
}
.content{
  max-height: 80vh;
  // min-height: 40vh;
  overflow-y: auto;
  padding-top: 1rem;
  &.is-search{
    padding-top: 2rem;
  }
}
</style>
<style lang='scss'>
.my-popup{
  overflow: hidden;
  &.van-image-preview{
    max-height: 100%;
  }
}
</style>
