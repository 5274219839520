<template>
  <AppDialog
    :visible="visible"
    title="Notice"
    confirm-button-text="Confirm Now"
    cancel-button-text="Later"
    @update:visible="emit('update:visible',false)"
    @handle-confirm="confirm"
  >
    <div class="content">
      <p class="mb30">Exchange rate confirmation is needed to proceed with your remittance.</p>
      <p>Confirm to complete the process.</p>
    </div>
  </AppDialog>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  orderSign: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:visible'])
function confirm() {
  emit('update:visible', false)
  router.push({
    name: 'TransactionDetail',
    params: {
      id: props.orderSign
    }
  })
}
</script>

<script>
export default { name: 'AppConfirmRateDialog' }
</script>

<style lang='scss' scoped>
.content{
  font-size: .26rem;
  color: $color99;
}
</style>
