<template>
  <van-field
    :rules="rules"
    class="custom-field my-radio"
  >
    <template #input>
      <van-radio-group
        :model-value="modelValue"
        :direction="direction"
        :disabled="disabled"
        @update:model-value="handleChange"
      >
        <van-radio
          v-for="item in columns"
          :key="item.value"
          :name="item.value"
        >{{ item.label }}</van-radio>
      </van-radio-group>
    </template>
  </van-field>
</template>

<script setup>
import { defaultChange } from '@/utils/rules'
import { toRefs } from 'vue'
const porps = defineProps({
  rules: {
    type: Array,
    default: () => defaultChange
  },
  direction: {
    type: String,
    default: 'horizontal'
  },
  modelValue: {
    type: [String, Number, Boolean],
    default: ''
  },
  columns: {
    type: Array,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:model-value'])
const { modelValue } = toRefs(porps)
function handleChange (e) {
  emit('update:model-value', e)
}

</script>

<script>
export default { name: 'AppRadio' }
</script>

<style lang='scss'>
// van-radio-group
.van-radio-group{
  .van-radio{
    margin-right: .4rem;
  }
  .van-radio__icon{
    font-size: .38rem;
    .van-icon{
      border-color: $blue;
    }
    &.van-radio__icon--checked{
      .van-icon{
        background-color: $blue;
      }
    }
  }
  .van-radio__label{
    margin-left: .12rem;
    font-size: .28rem;
    line-height: .4rem;
    color: $color66;
  }
  &.mini{
    .van-radio__icon{
      font-size: .30rem;
    }
  }
}
</style>
