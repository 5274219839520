<template>
  <div class="app-header">
    <div v-if="isShowBack" class="back-wrapper">
      <button class="back f32" @click="back">
        <van-icon name="arrow-left" />
      </button>
    </div>
    <div v-if="title || $slots.title" class="title-wrapper flex-sb-c">
      <h3 class="title w600">
        <slot name="title">
          {{ title }}
        </slot>
      </h3>
      <slot name="more" />
    </div>
    <p v-if="desc || $slots.desc" class="desc">
      <slot name="desc">
        {{ desc }}
      </slot>
    </p>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const props = defineProps({
  title: String,
  desc: String,
  customBack: {
    type: Boolean,
    default: false
  },
  isShowBack: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['back'])
function back() {
  if (props.customBack) {
    emit('back')
  } else {
    router.back()
  }
}
</script>

<script>
export default { name: 'AppHeader' }
</script>

<style lang='scss' scoped>
.app-header{
  padding: 0 $basePadding .34rem;
}
.back{
  padding: .2rem .2rem .2rem 0;
  color: $color33;
}
.title-wrapper{
  margin-top: .36rem;
}
.title{
  font-size: .36rem;
  line-height: .42rem;
  color: $color33;
}
.desc{
  margin-top: .1rem;
  font-size: .24rem;
  line-height: .28rem;
  color: $color99;
}
</style>
