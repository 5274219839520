import { getToken } from '@/utils/auth'
import { useApp } from '@/store/useApp'

let timer = null
const isDev = process.env.NODE_ENV === 'development'
const delay = isDev ? 1000 * 60 * 60 : 1000 * 60 * 2
function listenerFn() {
  const appStore = useApp()
  timer = setInterval(() => {
    if (!getToken()) return
    appStore.getClientPmTotal()
  }, delay)
}
window.addEventListener('load', function() {
  listenerFn()
})
window.addEventListener('visibilitychange', function() {
  if (document.hidden) {
    clearInterval(timer)
  } else {
    listenerFn()
  }
})

window.addEventListener('unload', function() {
  clearInterval(timer)
})
