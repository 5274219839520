<template>
  <van-field
    :model-value="modelValue"
    readonly
    v-bind="$attrs"
    :disabled="disabled"
    class="app-cascader input-select-icon"
    right-icon="arrow"
    @click="handleShow"
  />
  <AppPopup
    v-model:visible="showPicker"
    :title="title"
    :show-toolbar="false"
  >
    <van-cascader
      :model-value="cascaderValue"
      :show-header="false"
      :options="options"
      :placeholder="placeholder"
      @change="handleChange"
      @finish="onConfirm"
    >
      <template #option="{option}">
        <van-cell clickable>
          <template #title>
            <div class="title f28">
              {{ option.text }}
            </div>
          </template>
          <template #right-icon>
            <van-radio :name="option.value" />
          </template>
        </van-cell>
      </template>
    </van-cascader>
  </AppPopup>
</template>
<script setup>
import { computed, ref, watchEffect } from 'vue'

const props = defineProps({
  modelValue: String,
  title: String,
  options: {
    type: Array,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])
const showPicker = ref(false)

const _tabIndex = ref(undefined)
const placeholder = computed(() => _tabIndex.value === 0 ? 'city' : 'province')
function handleChange(data) {
  const { tabIndex } = data
  _tabIndex.value = tabIndex
}
const cascaderValue = ref(null)
watchEffect(() => {
  if (props.modelValue) {
    cascaderValue.value = props.modelValue.split(',')[1]
  }
})
function onConfirm ({ selectedOptions }) {
  const val = selectedOptions.map(item => item.value).join(',')
  emit('update:modelValue', val)
  showPicker.value = false
}
function handleShow() {
  if (props.disabled) return
  showPicker.value = true
}
</script>

<script>
export default {
  name: 'AppCascader',
  inheritAttrs: false
}
</script>
<style lang='scss'>
.app-cascader{
  &::after{
    display: none;
  }
  .van-cell__value {
    width: 100%;
  }
  &.van-cell:disabled{
    .van-field__body{
      .van-field__control{
        background: #fbfbfb !important;
        color: #bbb !important;
      }
    }
  }
}
.van-cascader{
  .van-tabs__wrap{
    height: auto !important;
  }
  .van-tabs__nav{
    padding: 0 .2rem .2rem;
    .van-tab {
      font-size: .28rem;
      line-height: .38rem;
      padding:0 .2rem;
      color: $color33;
      font-weight: 400 !important;
    }
    .van-cascader__tab--unselected{
      color: $color99;
    }
    .van-tabs__line{
      bottom: .1rem;
      background: #DCEDF6;
    }
  }
  .van-cascader__options{
    padding-top: 0;
    .van-cascader__option{
      padding: 0;
      .van-cell{
        padding: .3rem .4rem;
        .icon{
          width: .6rem;
          height: .6rem;
          margin-right: .22rem;
        }
        .title{
          font-weight: 400;
          color: $color33;
          line-height: .4rem;
        }
        .van-radio{
          margin-right: 0;
          .van-badge__wrapper{
            background: transparent !important;
            width: 0.36rem;
            height: .36rem;
            border-color: $color33;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before{
              content: '';
              display: block;
              width: 0;
              height: 0;
              background: $color33;
              border-radius: 50%;
              transition: all .28s ease-in-out;
            }
          }
          .van-radio__icon--checked{
            .van-badge__wrapper{
              &::before{
                width: .24rem;
                height: .24rem;
              }
            }
          }
        }
      }
      .van-cascader__selected-icon{
        display: none;
      }
      &.van-cascader__option--selected{
        .van-radio{
          .van-badge__wrapper{
            &::before{
              width: .24rem;
              height: .24rem;
            }
          }
        }
      }
    }
  }
}
</style>
