// 币种icon
export const currencyIconEnum = {
  HKD: 'HKD-icon',
  AUD: 'AUD-icon',
  USD: 'USD-icon',
  EUR: 'EUR-icon',
  GBP: 'GBP-icon',
  SGD: 'SGD-icon',
  CAD: 'CAD-icon',
  MYR: 'MYR-icon',
  ZAR: 'ZAR-icon',
  THB: 'THB-icon',
  CNY: 'CNY-icon',
  INR: 'INR-icon',
  PHP: 'PHP-icon',
  NZD: 'NZD-icon',
  JPY: 'JPY-icon',
  VND: 'VND-icon',
  BDT: 'BDT-icon',
  IDR: 'IDR-icon',
  BRL: 'BRL-icon',
  PKR: 'PKR-icon',
  KRW: 'KRW-icon',
  PLN: 'PLN-icon', // 波兰
  ARS: 'ARS-icon', // 阿根廷
  TRY: 'TRY-icon', // 土耳其
  MXN: 'MXN-icon', // 墨西哥
  ILS: 'ILS-icon', // 以色列
  SEK: 'SEK-icon', // 瑞典
  NOK: 'NOK-icon', // 挪威
  DKK: 'DKK-icon', // 丹麦
  CZK: 'CZK-icon', // 捷克
  KES: 'KES-icon', // 肯尼亚
  COP: 'COP-icon',
  UYU: 'UYU-icon',
  PEN: 'PEN-icon',
  HUF: 'HUF-icon', // 匈牙利
  RON: 'RON-icon', // 罗马尼亚
  NPR: 'NPR-icon', // 尼泊尔
  LKR: 'LKR-icon', // 斯里兰卡
  KHR: 'KHR-icon',
  CLP: 'CLP-icon',
  CRC: 'CRC-icon',
  MUR: 'MUR-icon',
  MAD: 'MAD-icon',
  NGN: 'NGN-icon',
  PYG: 'PYG-icon'
}

// 交易状态枚举
export const TransactionStatusEnum = {
  CREATED: 'Created', // 已创建
  WAIT_POST: 'Created', // 待入账
  WAIT_EDIT: 'Created', // 待编辑
  POSTED: 'Pending', // 已入账
  CONFIRMED: 'Processing', // 已确认汇率
  PAY_CLEAR_RETRY: 'Pending', // 失败重新下发
  PURCHASE_ING: 'Remitted', // 下发成功
  PURCHASED: 'Remitted', // 已购汇|已完成|资金到账|跨境
  PURCHASE_FAIL: 'Remitted', // 已完成
  PAY_CLEAR_FAIL: 'Failed', // 购汇失败
  PURCHASED_REFUNDED: 'Pending', // 已购汇且已退款|失败|资金退回
  REFUNDING: 'Refunding', // 退款中
  CANCELED: 'Canceled', // 已取消
  REFUNDED: 'Refunded' // 已退款
}
// 交易状态path
export const TransactionStatusColumns = [
  { label: 'Processing', value: 'PROCESSING' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Failed', value: 'FAILED' }
]

// 收款账户审核状态枚举
export const auditStatusEnum = {
  WAIT_AUDIT: 'Pending approval', // 待审核
  AUDITING: 'Under review', // 审核中
  PASS: 'Normal', // 审核通过
  REJECT: 'Rejected', // 审核拒绝
  REFUSE: 'Refused', // 驳回
  STOPPED: 'Disabled' // 已禁用
}
export function statusDistinguish(
  acctStatus,
  auditStatus,
  includeNormal = true
) {
  if (acctStatus === 'AUDITING' && auditStatus === 'REFUSE') {
    return {
      txt: 'Refused',
      color: 'REFUSE',
      tip: 'Contact customer service.'
    }
  } else if (
    (acctStatus === 'AUDITING' || acctStatus === 'NORMAL') &&
    auditStatus === 'AUDITING'
  ) {
    return {
      txt: 'Under review',
      color: 'AUDITING',
      tip: 'Name changed, review in 1 biz day.'
    }
  } else if (acctStatus === 'UNEFFECTIVE' && auditStatus === 'REJECT') {
    return {
      txt: 'Disabled',
      color: 'REJECT',
      tip: 'Not your own account.'
    }
  } else if (
    acctStatus === 'NORMAL' &&
    auditStatus === 'PASS' &&
    includeNormal
  ) {
    return {
      txt: 'Normal',
      color: 'PASS'
    }
  } else if (acctStatus === 'STOPPED') {
    return {
      txt: 'Disabled',
      color: 'STOPPED',
      tip: 'Not your own account.'
    }
  } else {
    return {
      txt: undefined,
      color: undefined
    }
  }
}
// 银行账户类型
export const bankAccountTypeColumns = [
  { label: 'Checking', value: 'checking' },
  { label: 'Savings', value: 'savings' }
]
// 银行账户类型
export const bankAccountTypeColumns_JPY = [
  { label: 'Time Deposit', value: 'timeDeposit' },
  { label: 'Others', value: 'others' }
]
// 银行账户类型枚举
export const bankAccountTypeEnum = {
  checking: 'Checking', // 支票账户
  savings: 'Savings', // 储蓄账户
  timeDeposit: 'Time Deposit', // 储蓄账户
  others: 'Others' // 储蓄账户
}
// 优惠券优惠类型文案展示
export const couponOffEnum = {
  100: 'Free coupon',
  50: '50% off coupon',
  30: '30% off coupon'
}
