/**
 * @description: 文本loading
 * @param {*} el 父节点节点
 * @param {*} value loading状态
 */
function textLoading(el, value) {
  if (value) {
    el.style.color = '#BFBFBF'
  } else {
    el.style.color = el._color
  }
}
const vTextLoading = {
  mounted(el, { value }) {
    el.style.transition = 'color .1s ease-in-out'
    el._color = el.style.color
    textLoading(el, value)
  },
  updated(el, { value }) {
    textLoading(el, value)
  }
}

export default vTextLoading
