<template>
  <van-button
    v-bind="$attrs"
    class="app-button"
  >
    <slot />
  </van-button>
</template>

<script>
export default { name: 'AppButton' }
</script>

<style lang='scss'>
.app-button{
  width: 100%;
  height: .96rem;
  line-height: .96rem;
  font-size: .3rem;
  border-radius: .2rem;
  font-family: 'Arial-Bold';
  &.van-button--default{
    background: #fff;
    border-color: #fff;
    color: $color33;
  }
  &.van-button--primary{
    background: $blue;
    border-color: $blue;
    color: #fff;
  }
  &.van-button--warning{
    background: #FFC500;
    border-color: #FFEC00;
    color: #fff;
  }
  &.van-button--danger{
    background: #fff;
    border-color: #fff;
    color: $red;
  }
  &.van-button--disabled{
    background: $colorCc;
    color: $color66;
    border-color: $colorCc;
  }
  &.van-button--mini{
    height: auto;
    min-width: 1.5rem;
    line-height: .36rem;
    font-size: .24rem;
    padding: .14rem .2rem;
    border-radius: .14rem;
  }
  &.van-button--text{
    min-width: auto;
    width: auto;
    display: inline-block;
    height: auto;
    background: transparent;
    color: $blue;
    vertical-align: middle;
    font-family: 'Arial-Regular';
    font-size: .24rem;
    padding: 0 !important;
    line-height: .36rem;
    &::before{
      display: none;
    }
    &:active{
      color: $hoverBlue;
    }
  }
  & + .el-button{
    margin-left: 0;
  }
}
</style>
