<template>
  <van-picker
    :columns="columns"
    :title="title"
    :confirm-button-text="confirmText"
    :cancel-button-text="cancelText"
    item-height=".76rem"
    :loading="loading"
    v-bind="$attrs"
  >
    <template #option="option">
      <svg-icon
        v-if="option.icon"
        class="f50 mr12"
        :icon-class="option.icon"
      />
      <span class="text">{{ option.label }}</span>
    </template>
  </van-picker>
</template>

<script setup>
defineProps({
  title: {
    type: String,
    default: 'Select'
  },
  cancelText: {
    type: String,
    default: 'Cancel'
  },
  confirmText: {
    type: String,
    default: 'Select'
  },
  columns: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  }
})
</script>

<script>
export default { name: 'AppPicker' }
</script>

<style scoped lang='scss'>
</style>
