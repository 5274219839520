<template>
  <div class="copyright">
    <p class="mb10">&copy; {{ year }}
      <a
        target="_blank"
        href="https://en.skyee360.com"
        class="link"
      >en.skyee360.com</a>
    </p>
    <p>Powered by Skyee, a member company of LAKALA (Stock Code:300773), regulated by the People’s Bank of China.</p>
  </div>
</template>

<script setup>
const year = new Date().getFullYear()
</script>

<script>
export default { name: 'AppFooter' }
</script>

<style scoped lang='scss'>
.copyright{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: .2rem .3rem 0;
  color: $color99;
  height: $footerHeight;
  font-size: .20rem;
  line-height: .28rem;
  text-align: center;
  .link{
    color: $color66;
    text-decoration: underline;
  }
}
</style>
