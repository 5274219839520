import { clientPmTotal, clientSetting, district, setting } from '@api/base'
import { defineStore } from 'pinia'

// 全局配置
export const useApp = defineStore('app', {
  state: () => {
    return {
      district: [],
      currencyList: [],
      clientPmTotal: null,
      cachedViews: [],
      remitCustomSetting: [],
      remitSetting: {},
      remitSystemNotice: {},
      remitUserNotice: {},
      clientSetting: {}
    }
  },
  actions: {
    // 国家
    getDistrict(params) {
      return new Promise((resolve, reject) => {
        // dictName 地区名称【COUNTRY_CD:全部国家,COLL:收款国家】
        district(params).then(res => {
          // console.log('国家列表', res)
          this.district = res.content
          // console.log(this.district)
          resolve(res.content)
        }).catch(() => {
          reject()
        })
      })
    },
    // 客户设置
    setting(custId) {
      return new Promise((resolve, reject) => {
        /*
          * remit_currency 币种设置
          * remit_setting 金额输入限制
          * remit_custom_setting 自定义限制
        */
        const params = {
          key: 'remit_currency,remit_setting,remit_custom_setting,remit_system_notice,remit_user_notice',
          custId
        }
        setting(params).then(res => {
          // console.log('客户设置', res)
          const {
            remitCurrency,
            remitCustomSetting,
            remitSetting,
            remitSystemNotice,
            remitUserNotice
          } = res
          this.currencyList = remitCurrency.filter(item => +item.status === 1).map(item => ({
            ...item,
            icon: item.value + '-icon'
          }))
          this.remitCustomSetting = remitCustomSetting
          this.remitSetting = remitSetting
          this.remitSystemNotice = remitSystemNotice
          this.remitUserNotice = remitUserNotice

          const VERSION = localStorage.getItem('appVersion')
          if (VERSION && remitSetting.version !== VERSION && !/wechatlogin/i.test(window.location.href)) {
            location.reload()
          }
          localStorage.setItem('appVersion', remitSetting.version)
          resolve(res.content)
        }).catch(() => {
          reject()
        })
      })
    },
    // 客户设置
    getClientSetting() {
      return new Promise((resolve, reject) => {
        clientSetting().then(res => {
          // console.log('客户设置', res)
          this.clientSetting = res.noticeSetting
          resolve()
        }).catch(() => {
          reject()
        })
      })
    },
    // 消息统计数量
    getClientPmTotal(params) {
      return new Promise((resolve, reject) => {
        clientPmTotal(params).then(res => {
          // console.log('客户消息统计', res)
          this.clientPmTotal = res.isNewCount
          resolve(res.isNewCount)
        }).catch(() => {
          reject()
        })
      })
    },
    // 添加缓存
    addCachedView(view) {
      const { params, name } = view
      if (this.cachedViews.includes(name)) return
      const paramsList = Object.keys(params)
      const whiteList = ['RecipientsAdd']
      if (!paramsList.length && !whiteList.includes(name)) {
        this.cachedViews = []
      }
      if (view.meta?.cache) {
        this.cachedViews.push(name)
      }
    },
    // 删除缓存
    delCachedView() {
      this.cachedViews = []
    }
  },
  getters: {
    _district: state => state.district,
    _currencyList: state => state.currencyList,
    _clientPmTotal: state => state.clientPmTotal,
    _cachedViews: state => state.cachedViews,
    _remitCustomSetting: state => state.remitCustomSetting,
    _remitSetting: state => state.remitSetting,
    _remitSystemNotice: state => state.remitSystemNotice,
    _remitUserNotice: state => state.remitUserNotice,
    _clientSetting: state => state.clientSetting
  }
})
