<template>
  <AppNotifyKyc />
  <AppNotifyRemit />
</template>

<script setup>
import AppNotifyKyc from './kyc'
import AppNotifyRemit from './remit'

</script>

<script>
export default { name: 'AppNotify' }
</script>

<style lang='scss'>
.app-notify.van-popup{
  top: .18rem;
  right: .18rem;
  left: .18rem;
  border-radius: .2rem;
  background: #fff;
  color: $color33;
  justify-content: flex-start;
  padding: .20rem .62rem .20rem .24rem !important;
  font-size: .24rem !important;
  width: auto;
  overflow: hidden;
  border: .01rem solid $color33 !important;
  .content{
    flex: 1;
    .icon{
      flex-shrink: 0;
      margin-right: .1rem;
      font-size: .38rem;
    }
    .text{
      flex: 1;
      text-align: center;
    }
  }
  .close{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: .2rem;
    color: $color99;
    font-size: .30rem;
  }
}
</style>
