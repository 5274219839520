import { getToken, removeToken, setToken } from '@/utils/auth'
import { profile, clientBasis, register, login, logout, weChatLogin } from '@api/user'
import { defineStore } from 'pinia'
import { useApp } from '@/store/useApp'
// 用户信息
export const useUser = defineStore('userInfo', {
  state: () => {
    return {
      token: getToken(),
      profile: {},
      clientAuth: {},
      clientBasis: {},
      clientUser: {}
    }
  },
  actions: {
    // 注册
    register(params) {
      return new Promise((resolve, reject) => {
        register(params).then(res => {
          // console.log('注册', res)
          setToken(res.token)
          this.token = res.token
          resolve(res)
        }).catch(() => {
          reject()
        })
      })
    },
    // 登录
    login(params) {
      return new Promise((resolve, reject) => {
        login(params).then(res => {
          // console.log('登录', res)
          setToken(res.token)
          this.token = res.token
          resolve(res)
        }).catch(() => {
          reject()
        })
      })
    },
    // 微信登录
    weChatLogin(params) {
      return new Promise((resolve, reject) => {
        weChatLogin(params).then(res => {
          // console.log('微信登录', res)
          setToken(res.token)
          this.token = res.token
          resolve(res)
        }).catch(() => {
          reject()
        })
      })
    },
    // 个人信息
    getProfile() {
      return new Promise((resolve, reject) => {
        const params = {
          t: Date.now()
        }
        profile(params).then(async (res) => {
          // console.log('客户个人资料', res)
          this.profile = res
          const appStore = useApp()
          await appStore.setting(res.custId)
          resolve(res)
        }).catch(() => {
          reject()
        })
      })
    },
    // 客户基础信息
    getClientBasis() {
      return new Promise((resolve, reject) => {
        const params = {
          t: Date.now()
        }
        clientBasis(params).then(res => {
          // console.log('客户基础信息', res)
          const {
            clientAuth,
            clientBasis,
            clientUser
          } = res
          this.clientAuth = clientAuth
          this.clientBasis = clientBasis
          this.clientUser = clientUser
          resolve(res)
        }).catch(() => {
          reject()
        })
      })
    },
    // 退出
    logout() {
      return new Promise((resolve, reject) => {
        const params = {
          t: Date.now()
        }
        logout(params).then(res => {
          // console.log('退出登录', res)
          this.resetToken()
          resolve(res)
        }).catch(() => {
          reject()
        })
      })
    },
    resetToken() {
      removeToken()
      this.$reset()
      return Promise.resolve()
    }
  },
  getters: {
    _token: state => state.token,
    _profile: state => state.profile,
    _clientAuth: state => state.clientAuth,
    _clientBasis: state => state.clientBasis,
    _clientUser: state => state.clientUser
  }
})
