<template>
  <div class="app-wrapper">
    <AppMain />
    <AppTabbar />
  </div>
  <!-- 分享二维码 -->
  <AppCodeDialog v-model:visible="codeVisible" />
  <!-- 确认汇率 -->
  <AppConfirmRateDialog
    v-model:visible="confirmVisible"
    class="confirm-rate-dialog"
    :order-sign="orderSignId"
  />
  <!-- 待办提示 -->
  <AppNotifyKyc />
  <!-- 系统提示-所有/部分客户通知-->
  <AppSystemNotify :system-notice="systemNotice" @close-modal="modalNextFn" />
</template>

<script setup>
import AppCodeDialog from '@/components/codeDialog'
import AppConfirmRateDialog from '@/components/confirmRateDialog'
import AppNotifyKyc from '@/components/notify'
import AppSystemNotify from '@/components/systemNotify'
import { useApp } from '@/store/useApp'
import { useUser } from '@/store/useUser'
import { isWX, safariDate } from '@/utils'
import { partRemitDialog, remitDialog } from '@/utils/auth'
import { getFocus } from '@/utils/focus'
import { checkWeChat } from '@api/account'
import { remitOrderNotice } from '@api/base'
import { onMounted, ref } from 'vue'
import AppMain from './main.vue'
import AppTabbar from './tabbar.vue'
import Cookies from 'vue-cookies'
const userStore = useUser()
const appStore = useApp()
// 二维码弹窗
const codeVisible = ref(false)
const systemNotice = ref(null)
function getCheckWechat() {
  checkWeChat().then(res => {
    const { isSubscribe } = res
    if (!isSubscribe) {
      codeVisible.value = true
    }
  })
}
// 确认汇率弹窗
const confirmVisible = ref(false)
const orderSignId = ref(null)
function getRemitOrderNotice() {
  remitOrderNotice().then(res => {
    // console.log('汇款单已入账通知', res)
    const { orderSign } = res
    if (orderSign) {
      confirmVisible.value = true
      orderSignId.value = orderSign
    }
  })
}
// 创建一个数组，包含要显示的弹窗内容
const modalContents = []
const modalNextFn = ref(null)
// 弹出弹窗的函数
function showModal(content, callback) {
  console.log(content)
  systemNotice.value = { ...content }
  modalNextFn.value = callback
}

// 按顺序显示多个弹窗的函数
function showModalsInOrder(contents, index) {
  // 判断是否已经显示完所有弹窗
  if (index >= contents.length) {
    return
  }

  // 显示当前弹窗
  showModal(contents[index], () => {
    // 当前弹窗关闭后，递归调用showModalsInOrder显示下一个弹窗
    showModalsInOrder(contents, index + 1)
  })
}
function isConditionPass(systemNotice, type) {
  if (!systemNotice) return
  const { noticeEndTime, noticeStartTime, status } = systemNotice
  const timestamp = new Date().getTime()
  const t1 = +status === 1
  const t2 = !Cookies.get(type)
  const t3 = !noticeStartTime || timestamp > safariDate(noticeStartTime).getTime()
  const t4 = !noticeEndTime || safariDate(noticeEndTime).getTime() > timestamp
  return t1 && t2 && t3 && t4
}
if (isConditionPass(appStore._remitSystemNotice, remitDialog)) {
  appStore._remitSystemNotice.remitDialog = remitDialog
  modalContents.push(appStore._remitSystemNotice)
}
if (isConditionPass(appStore._remitUserNotice, partRemitDialog)) {
  appStore._remitUserNotice.remitDialog = partRemitDialog
  modalContents.push(appStore._remitUserNotice)
}
showModalsInOrder(modalContents, 0)
onMounted(() => {
  if (isWX() && userStore._clientBasis.openid && !getFocus()) {
    getCheckWechat()
  }
  if (userStore._profile.status !== undefined && userStore._clientBasis.orderConfirmed > 0) {
    getRemitOrderNotice()
  }
})
</script>

<script>
export default { name: 'Layout' }
</script>

<style lang='scss'>
.confirm-rate-dialog {
  width: 5.9rem !important;
}
</style>
