<template>
  <div class="table-wrapper">
    <table>
      <colgroup>
        <col
          v-for="(item,index) in tableConfig"
          :key="index"
          :style="{
            'width': item.width,
            'text-align':'center'
          }"
        >
      </colgroup>
      <thead>
        <tr>
          <th v-for="item in tableConfig" :key="item.label">
            {{ item.label }}
          </th>
        </tr>
      </thead>
    </table>
    <div
      class="tbody"
      :class="{
        'init-data': !dataList.length && loading
      }"
      :style="{'max-height':maxHeight}"
    >
      <div v-if="loading" class="loading-wrapper flex-c">
        <van-loading color="#00A6FD" size=".6rem" />
      </div>
      <div v-if="!dataList.length && !loading" class="empty">
        <AppListEmpty
          :img-url="emptyImg"
          tips="No data"
        />
      </div>
      <table>
        <colgroup>
          <col
            v-for="(item,index) in tableConfig"
            :key="index"
            :style="{
              'width': item.width,
              'text-align':'center'
            }"
          >
        </colgroup>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td v-for="column in tableConfig" :key="column.prop">
              <div class="cell">
                <slot
                  :name="column.prop"
                  :prop="item[column.prop]"
                  :scoped="item"
                >
                  {{ item[column.prop] }}
                </slot>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
defineProps({
  dataList: {
    type: Array,
    default: () => []
  },
  tableConfig: {
    type: Array,
    default: () => []
  },
  maxHeight: {
    type: String,
    default: 'auto'
  },
  loading: {
    type: Boolean,
    default: false
  }
})
const emptyImg = require('@/assets/empty/data.png')
</script>

<script>
export default { name: 'AppTable' }
</script>

<style scoped lang='scss'>
.table-wrapper{
  table{
    width: 100%;
    table-layout:fixed;
    th,td{
      font-weight: none;
      color: $color33;
      font-size: .22rem;
      line-height: .36rem;
      padding: .1rem .06rem;
      text-align: center;
      white-space: normal;
    }
    td{
      color: $color66;
      .cell{
        width: 100%;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.tbody{
  overflow-y: auto;
  position: relative;
  &.init-data,
  .empty{
    min-height: 2rem;
  }
  .loading-wrapper{
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
