<template>
  <van-field
    :model-value="modelValue"
    readonly
    v-bind="$attrs"
    :disabled="disabled"
    right-icon="arrow"
    class="app-index-bar input-select-icon"
    @click="handleShow"
  >
    <template v-if="modelValue" #input>
      <slot>
        <div class="flex-c">
          <van-image
            v-if="iconSrc"
            :round="roundIcon"
            object-fit="cover"
            lazy-load
            width=".5rem"
            :height="roundIcon ? '.5rem' : ''"
            style="margin-left: 0.2rem"
            :src="require(`@/assets/img/${iconSrc}.png`)"
          />
          <div class="select-inner van-ellipsis">
            {{ showValue(modelValue) }}
          </div>
        </div>
      </slot>
    </template>
  </van-field>
  <van-popup
    v-model:show="showPicker"
    class="index-bar-popup"
    position="bottom"
    teleport="body"
    safe-area-inset-bottom
  >
    <div
      class="index-bar-top"
      :class="{ 'is-search': searchList.length && searchValue }"
    >
      <div class="top-inner">
        <div class="title flex-sb-c">
          <div class="t w500">{{ headerTitle }}</div>
          <button class="cancel" @click="showPicker = false">
            <van-icon name="close" />
          </button>
        </div>
        <div class="search">
          <van-field
            v-model="searchValue"
            left-icon="search"
            clearable
            :placeholder="searchPlaceholder"
            @input="handleSearch"
          />
        </div>
      </div>
      <div v-if="searchList.length && searchValue" class="search-content">
        <div
          v-for="item in searchList"
          :key="item.value"
          class="search-item"
          @click="onConfirm(item)"
        >
          <div class="flex-c">
            <van-image
              v-if="item.icon"
              :round="roundIcon"
              object-fit="cover"
              lazy-load
              width=".5rem"
              :height="roundIcon ? '.5rem' : ''"
              style="margin-right: 0.2rem"
              :src="require(`@/assets/img/${item.icon}.png`)"
            />
            <div v-html="highlightText(item.label)" />
          </div>
        </div>
      </div>
      <div
        v-else-if="searchValue"
        class="fs28"
        style="color: #ccc; padding: 0.3rem 0 0 0.3rem; height: 100vh"
      >
        No Result
      </div>
    </div>
    <div v-if="!searchValue" class="index-bar-wrapper">
      <van-index-bar highlight-color="#00A6FD" :sticky-offset-top="99">
        <template v-for="item in option" :key="item.index">
          <van-index-anchor :index="item.index" />
          <van-cell
            v-for="v in item.content"
            :key="v.value"
            :title-style="{ color: v.isRed ? 'red' : '' }"
            :title="v.label"
            center
            @click="onConfirm(v)"
          >
            <template #icon>
              <van-image
                v-if="v.icon"
                :round="roundIcon"
                object-fit="cover"
                width=".5rem"
                lazy-load
                :height="roundIcon ? '.5rem' : ''"
                style="margin-right: 0.2rem"
                :src="require(`@/assets/img/${v.icon}.png`)"
              />
            </template>
          </van-cell>
        </template>
      </van-index-bar>
    </div>
  </van-popup>
</template>

<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  modelValue: String,
  columns: {
    type: Array,
    default: () => { }
  },
  disabled: {
    type: Boolean,
    default: false
  },
  headerTitle: {
    type: String,
    default: 'Country/Region'
  },
  searchPlaceholder: {
    type: String,
    default: 'Search country/region'
  },
  roundIcon: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['update:modelValue'])
const iconSrc = ref(null)
function formatCountry(list) {
  const sortList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  const _list = sortList.map(index => {
    const groupList = []
    list.forEach(item => {
      if (item.label.startsWith(index)) {
        groupList.push(item)
      }
    })
    return {
      index,
      content: groupList
    }
  })
  return _list
}
const option = computed(() => formatCountry(props.columns))
function handleShow() {
  if (props.disabled) return
  showPicker.value = true
}

// 查找columns对应item
function findItem(val) {
  return props.columns.find(item => item.value === val) || {}
}
// 展示value
function showValue(val) {
  if (!val) return
  const item = findItem(val)
  iconSrc.value = item.icon
  return item.label
}
const searchValue = ref(null)
const searchList = ref([])
// 格式化大小写
function toLowerCase(v) {
  if (!v) return
  return v.toLowerCase()
}
// 高亮字体
function highlightText(text) {
  if (!searchValue.value) {
    return text
  }
  const regex = new RegExp(searchValue.value, 'gi')
  return text.replace(regex, match => `<span class="highlight">${match}</span>`)
}
// 搜索
function handleSearch(e) {
  const v = e.target.value
  if (!v) {
    searchList.value = []
    return
  }
  const list = props.columns.filter(item => {
    const label = toLowerCase(item.label)
    const val = toLowerCase(v)
    return label.includes(val)
  })
  searchList.value = list
}
const showPicker = ref(false)
function onConfirm(val) {
  const { value, icon } = val
  iconSrc.value = icon
  emit('update:modelValue', value)
  searchValue.value = undefined
  showPicker.value = false
}

</script>

<script>
export default { name: 'AppIndexBar' }
</script>

<style scoped lang='scss'>
.select-inner {
  padding: 0 0.4rem 0 0.2rem;
}
.index-bar-wrapper {
  padding-top: 100px;
  position: relative;
}
</style>
<style lang='scss'>
.index-bar-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  padding: 0 0.3rem;
  .top-inner {
    height: 100px;
  }
  &.is-search {
    bottom: 0;
  }
  .title {
    height: 50px;
    .t {
      color: $color33;
    }
    .cancel {
      font-size: 0.4rem;
      color: $color66;
      background: #fff;
    }
  }
  .search {
    .van-cell {
      padding: 0 0.2rem;
      border-radius: 0.2rem;
      height: 0.84rem;
      line-height: 0.84rem;
      border: 0.01rem solid $colorCc;
      .van-icon-search {
        font-size: 0.3rem;
      }
      .van-icon-clear {
        font-size: 0.3rem;
        display: block;
      }
    }
    .van-field__left-icon {
      color: $colorCc;
    }
  }
  .search-content {
    position: absolute;
    top: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 0.2rem 0.3rem;
    overflow-x: hidden;
    .search-item {
      padding: 0.2rem 0;
      color: $color33;
      font-size: 0.28rem;
      line-height: 0.48rem;
      position: relative;
      cursor: pointer;
      .highlight {
        color: $blue;
      }
      &::before {
        content: "";
        position: absolute;
        box-sizing: border-box;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid #ebedf0;
        transform: scaleY(0.5);
      }
    }
  }
}
.app-index-bar {
  &::after {
    display: none;
  }
  .van-cell__value {
    width: 100%;
  }
  &.van-cell.is-disabled {
    .van-field__body {
      .van-field__control {
        background: #fbfbfb !important;
        color: #bbb !important;
      }
    }
  }
}
.van-index-bar {
  .van-cell {
    padding: 0.2rem 0.3rem;
    color: $color33;
    font-size: 0.28rem;
  }
  .van-index-anchor {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    background: $baseBg;
  }
}
</style>
<style lang="scss">
.index-bar-popup {
  max-height: 100% !important;
}
</style>
