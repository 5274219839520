<!-- 气泡弹出框 -->
<template>
  <van-popover
    v-model:show="showPopover"
    :placement="placement"
    class="app-popover"
  >
    <div class="content">
      <slot>
        <p v-if="isHtml" v-html="content" />
        <p v-else>{{ content }}</p>
      </slot>
    </div>
    <template #reference>
      <slot name="reference">
        <van-icon
          :color="color"
          class="icon"
          name="question-o"
        />
      </slot>
    </template>
  </van-popover>
</template>

<script setup>
import { computed, ref } from 'vue'
const props = defineProps({
  content: String,
  color: {
    type: String,
    default: '#222222'
  },
  placement: {
    type: String,
    default: 'top'
  }
})
const isHtml = computed(() => /<br\/>/.test(props.content))
const showPopover = ref(false)

</script>

<script>
export default { name: 'AppPopover' }
</script>

<style scoped lang='scss'>
.content{
  padding: .2rem .2rem .2rem .44rem;
  font-size: .24rem;
  color: $color33;
  line-height: .28rem;
  position: relative;
  &::after{
    content: '';
    position: absolute;
    display: block;
    width: .12rem;
    height: .12rem;
    background: $blue;
    border-radius: 50%;
    top: 50%;
    left: .2rem;
    transform: translateY(-50%);
  }
}
.icon{
  font-size: .28rem;
}
</style>
<style lang="scss">
.app-popover{
  .van-popover__content{
    max-width: 4rem;
    border-radius: .34rem;
  }
}
.van-popover__arrow {
  bottom: 0.02rem !important;
}
</style>
