<template>
  <VanNotify
    v-model:show="show"
    class="app-notify"
    @click="handleClick"
  >
    <div class="content flex-c">
      <svg-icon class="icon" icon-class="messages" />
      <span class="text">
        {{ message }}
      </span>
    </div>
    <button class="close" @click.stop="show = false">
      <van-icon name="cross" />
    </button>
  </VanNotify>
</template>

<script setup>
import { Notify } from 'vant'
import { ref, computed, onMounted } from 'vue'
import { useUser } from '@/store/useUser'
import { getRealAuth } from '@api/kyc'
import { useRouter } from 'vue-router'
const VanNotify = Notify.Component
const userStore = useUser()
const router = useRouter()

const authStatus = computed(() => userStore._clientAuth.authStatus)

const certStatus = ref(null)
function realAuth() {
  return new Promise((resolve, reject) => {
    getRealAuth().then(res => {
    // console.log('实名', res)
      certStatus.value = res.certStatus
      resolve()
      show.value = true
    }).catch(() => {
      reject()
    })
  })
}
const sEnum = {
  REFUSE: 'Authentication rejected.',
  AUDITING: 'Verification in progress.'
}
const message = computed(() => certStatus.value === 'CREATED' ? 'Documents not uploaded.' : (sEnum[authStatus.value] || 'Not verified.'))

const show = ref(false)

onMounted(async() => {
  if (userStore._profile.status === undefined) return
  if (!authStatus.value) {
    await realAuth()
  }
  if (authStatus.value === 'AUDITING' || authStatus.value === 'REFUSE' || !authStatus.value) {
    show.value = true
  }
})
function handleClick() {
  show.value = false
  // 未kyc
  if (!authStatus.value) {
    router.push('/kyc')
  }
  // 未完成kyc
  if (certStatus.value === 'CREATED') {
    router.push({
      path: '/kyc',
      query: {
        step: '2'
      }
    })
  }
  // 驳回
  if (authStatus.value === 'REFUSE') {
    router.push('/kyc-edit')
  }
}

</script>

<script>
export default { name: 'AppNotifyKyc' }
</script>

