import axios from '@/utils/request'

export function getRemitOrder(params) {
  return axios({
    url: 'api/v1/RemitOrder',
    method: 'get',
    params
  })
}
export function editRemitOrder(data) {
  return axios({
    url: 'api/v1/RemitOrder',
    method: 'put',
    data
  })
}
export function remitOrderDetail(params) {
  return axios({
    url: 'api/v1/RemitOrderDetail',
    method: 'get',
    params
  })
}
export function paymentOrderVoucher(params) {
  return axios({
    url: 'api/v1/PaymentOrderVoucher',
    method: 'get',
    params
  })
}
export function editRemitOrderDetail(data) {
  return axios({
    url: 'api/v1/RemitOrderDetail',
    method: 'put',
    data
  })
}

export function remitOrderConfirm(data) {
  return axios({
    url: 'api/v1/RemitOrderConfirm',
    method: 'put',
    data
  })
}
export function deleteRemitOrder(data) {
  return axios({
    url: 'api/v1/RemitOrder',
    method: 'delete',
    data
  })
}
// 计算交易手续费费率-拉卡拉
export function lakalaCalcFeeRate(data) {
  return axios({
    url: 'api/v1/LakalaCalcFeeRate',
    method: 'post',
    data
  })
}
// 收银台订单创建-拉卡拉
export function lakalaOrder(data) {
  return axios({
    url: 'api/v1/LakalaOrder',
    method: 'post',
    data
  })
}
