<template>
  <div v-if="_count > 1" class="pagination-wrapper">
    <van-pagination
      :model-value="page"
      :page-count="_count"
      mode="simple"
      @update:model-value="emitInput"
      @click="change"
    />
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue'
const props = defineProps({
  page: {
    type: [String, Number],
    default: null
  },
  pageSize: {
    type: [String, Number],
    default: null
  },
  count: {
    type: [String, Number],
    default: null
  }
})
const { page } = toRefs(props)
const emit = defineEmits(['change', 'update:page'])
const _count = computed(() => Math.ceil(props.count / props.pageSize))

function emitInput(v) {
  emit('update:page', v)
}
function change() {
  emit('change')
}
</script>

<script>
export default { name: 'AppPagination' }
</script>

<style lang='scss'>
.pagination-wrapper{
  .van-pagination__item{
    height: .8rem;
    color: $blue;
    font-size: .28rem;
    &.van-pagination__item--disabled{
      color: $color66;
    }
    &:active{
      background: rgba(102,199,242,0.1);
    }
  }
  .van-pagination__page-desc{
    height: .8rem;
    color: $color33;
    font-size: .24rem;
  }
}
</style>
