import axios from '@/utils/request'

export function register(data) {
  return axios({
    url: 'api/v1/Register',
    method: 'post',
    data
  })
}
export function login(data) {
  return axios({
    url: 'api/v1/Login',
    method: 'post',
    data
  })
}

export function profile(params) {
  return axios({
    url: 'api/v1/Profile',
    method: 'get',
    params
  })
}
export function clientBasis(params) {
  return axios({
    url: 'api/v1/ClientBasis',
    method: 'get',
    params
  })
}
export function logout(params) {
  return axios({
    url: 'api/v1/Logout',
    method: 'get',
    params
  })
}
// 微信登录
export function weChatLogin(params) {
  return axios({
    url: 'api/v1/WeChatLogin',
    method: 'get',
    params
  })
}
