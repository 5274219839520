import axios from '@/utils/request'

// 上传文件
export function upload(data) {
  return axios({
    url: 'api/v1/ProxyUpload',
    method: 'post',
    data
  })
}
// 获取中台存储的图片文件
export function getUpload(params) {
  return axios({
    url: 'api/v1/ProxyUpload',
    method: 'get',
    params
  })
}
// 获取邮箱验证码
export function SendMail(data) {
  return axios({
    url: 'api/v1/SendMail',
    method: 'post',
    data
  })
}
// 获取除开登录以外其他手机验证码
export function sms(data) {
  return axios({
    url: 'api/v1/Sms',
    method: 'post',
    data
  })
}
// 获取登录验证码
export function loginSms(data) {
  return axios({
    url: 'api/v1/SendValidateCode',
    method: 'post',
    data
  })
}
// 国家列表
export function district(params) {
  return axios({
    url: 'api/v1/District',
    method: 'get',
    params
  })
}
// 代理银行配置
export function proxyBank(params) {
  return axios({
    url: 'api/v1/ProxyBank',
    method: 'get',
    params
  })
}
// 微信配置
export function weChatJs(params) {
  return axios({
    url: 'api/v1/WeChatJs',
    method: 'get',
    params
  })
}
// 客户消息统计
export function clientPmTotal(params) {
  return axios({
    url: 'api/v1/ClientPmTotal',
    method: 'get',
    params
  })
}
// 汇款单已入账通知
export function remitOrderNotice(params) {
  return axios({
    url: 'api/v1/RemitOrderNotice',
    method: 'get',
    params
  })
}
// 账号信息变更
export function accountChange(data) {
  return axios({
    url: 'api/v1/AccountChange',
    method: 'put',
    data
  })
}
// 客户设置
export function clientSetting(params) {
  return axios({
    url: 'api/v1/ClientSetting',
    method: 'get',
    params
  })
}
// 编辑客户设置
export function editClientSetting(data) {
  return axios({
    url: 'api/v1/ClientSetting',
    method: 'put',
    data
  })
}
// 系统设置
export function setting(params) {
  return axios({
    url: 'api/v1/Setting',
    method: 'get',
    params
  })
}
