<template>
  <div class="search-box">
    <van-field
      ref="searchIpt"
      v-model="searchValue"
      v-bind="$attrs"
      name="search-box"
      @input="handleSearch"
      @focus="handleSearch"
    />

    <!-- 搜索结果组件 -->
    <div
      v-if="searchResults.length && searchValue"
      ref="searchBox"
      class="search-results"
    >
      <div :class="[searchResults.length > 5 ? 'h4rem' : 'mh4rem']">
        <VirtList item-key="value" :list="searchResults" :min-size="20">
          <template #default="{ itemData }">
            <div class="search-item" @click="onConfirm(itemData)">
              <div v-html="highlightText(itemData.label)" />
            </div>
          </template>
        </VirtList>
      </div>
      <!-- <div
        v-for="(result, index) in searchResults"
        :key="index"
        class="search-item"
        @click="onConfirm(result)"
      >
        <div v-html="highlightText(result.label)" />
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { VirtList } from 'vue-virt-list'
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  columns: {
    type: Array,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  },
  searchPlaceholder: {
    type: String,
    default: ''
  }
})
const searchValue = defineModel({ type: String })
const searchResults = ref([])
const searchBox = ref(null)
const searchIpt = ref(null)
function toLowerCase(v) {
  if (!v) return
  return v.toLowerCase()
}
function handleSearch(e) {
  const v = e.target.value
  if (!v || props.columns.length === 0) {
    searchResults.value = []
    return
  }
  const list = props.columns.filter(item => {
    const label = toLowerCase(item.label)
    const val = toLowerCase(v)
    return label.includes(val)
  })
  searchResults.value = list
}
function highlightText(text) {
  if (!searchValue.value) {
    return text
  }
  const regex = new RegExp(searchValue.value, 'gi')
  return text.replace(regex, match => `<span style="color: #00a6fd">${match}</span>`)
}
function handleClickOutside(e) {
  if (e.target.name === 'search-box') return
  if (searchBox.value && !searchBox.value.contains(e.target)) { // contains 比较元素是否相等
    searchResults.value = []
  }
}
function onConfirm(val) {
  const { value } = val
  emit('update:modelValue', value)
  searchResults.value = []
}
onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>
<script>
export default { name: 'AppSearchBox' }
</script>
<style scoped lang='scss'>
.search-box {
  position: relative;
}

.search-input {
  width: 100%;
  padding: 0.2rem;
}

.search-results {
  position: absolute;
  width: 100%;
  overflow: auto;
  top: 1rem;
  left: 0;
  z-index: 999;
  padding: 0.2rem;
  background-color: #fff;
  border-radius: 0.2rem;
  box-shadow: 0 0 1px 2px #ccc;
}
.search-item {
  padding: 0.2rem 0;
  color: #222;
  font-size: 0.28rem;
  line-height: 0.48rem;
  position: relative;
  cursor: pointer;
}
.search-item::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #ebedf0;
  transform: scaleY(0.5);
}
.h4rem {
  height: 4rem;
}
.mh4rem {
  max-height: 4rem;
}
</style>
