<template>
  <van-field
    ref="input"
    :model-value="modelValue"
    :type="type"
    v-bind="$attrs"
    class="password-input"
    @update:model-value="emitInput"
  >
    <template #right-icon>
      <button type="button" @click="toggle">
        <svg-icon class="icon" :icon-class="type === 'password' ? 'eye' : 'eye-open'" />
      </button>
    </template>
  </van-field>
</template>

<script setup>
import { nextTick, ref } from 'vue'

defineProps({
  modelValue: String
})
const emit = defineEmits(['update:modelValue'])
function emitInput (val) {
  emit('update:modelValue', val)
}
const type = ref('password')
const input = ref(null)
function toggle() {
  type.value = type.value === 'password' ? '' : 'password'
  nextTick(() => {
    input.value.focus()
  })
}
</script>

<script>
export default { name: 'AppPasswordInput' }
</script>

<style lang='scss'>
.password-input{
  .van-field__right-icon{
    margin-right: 0;
    padding: 0;
    button{
      padding: 0 .38rem;
      font-size: .32rem;
    }
  }
}
</style>
