import { createRouter, createWebHistory } from 'vue-router'

import Layout from '@/layout'

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/send',
    children: [
      {
        path: 'send',
        name: 'Send',
        component: () => import('@/views/send')
      }
    ]
  },
  {
    path: '/recipients',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Recipients',
        component: () => import('@/views/recipients'),
        meta: { cache: true, activeMenu: '/account' }
      },
      {
        path: 'add',
        name: 'RecipientsAdd',
        component: () => import('@/views/recipients/add'),
        meta: { activeMenu: '/recipients' }
      },
      {
        path: 'edit/:id',
        name: 'RecipientsEdit',
        component: () => import('@/views/recipients/edit'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'details/:id',
        name: 'RecipientsDetails',
        component: () => import('@/views/recipients/details'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'select-acc',
        name: 'SelectAcc',
        component: () => import('@/views/recipients/selectAcc'),
        meta: { activeMenu: '/recipients' }
      }
    ]
  },
  {
    path: '/transaction',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Transaction',
        component: () => import('@/views/transaction'),
        meta: { cache: true }
      },
      {
        path: 'transaction-detail/:id',
        name: 'TransactionDetail',
        component: () => import('@/views/transaction/detail'),
        meta: { activeMenu: '/transaction' }
      }
    ]
  },
  {
    path: '/account',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Account',
        component: () => import('@/views/account')
      },
      {
        path: 'security',
        name: 'Security',
        component: () => import('@/views/account/security'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'verification-info',
        name: 'VerificationInfo',
        component: () => import('@/views/account/verificationInfo'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'coupons',
        name: 'Coupons',
        component: () => import('@/views/account/coupons'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'supplementary-materials',
        name: 'SupplementaryMaterials',
        component: () => import('@/views/account/supplementaryMaterials'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'add-bank-acc-number/:orderSign/:payMode',
        name: 'AccNumber',
        component: () => import('@/views/account/supplementaryMaterials/accNumber'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'add-identity',
        name: 'Identity',
        component: () => import('@/views/account/supplementaryMaterials/identity'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'add-tax-records',
        name: 'TaxRecords',
        component: () => import('@/views/account/supplementaryMaterials/taxRecords'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'acc-setting',
        name: 'AccountSetting',
        component: () => import('@/views/account/accountSetting'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'setting',
        name: 'RemitSetting',
        component: () => import('@/views/account/remitSetting'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'transfer-limit',
        name: 'TransferLimit',
        component: () => import('@/views/account/remitSetting/transferLimit'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'feedback',
        name: 'Feedback',
        component: () => import('@/views/account/remitSetting/feedback'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'set-email',
        name: 'SetEmail',
        component: () => import('@/views/account/accountSetting/setEmail'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'set-mobile',
        name: 'SetMobile',
        component: () => import('@/views/account/accountSetting/setMobile'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'terms-privacy',
        name: 'TermsPrivacy',
        component: () => import('@/views/account/termsPrivacy'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'faq',
        name: 'Faq',
        component: () => import('@/views/account/faq'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'faq-detail/:id',
        name: 'FaqDetail',
        component: () => import('@/views/account/faq/detail'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'invitation',
        name: 'Invitation',
        component: () => import('@/views/account/invitation'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'bonus-list',
        name: 'BonusList',
        component: () => import('@/views/account/invitation/bonusList')
      },
      {
        path: 'withdrawal-history',
        name: 'WithdrawalHistory',
        component: () => import('@/views/account/invitation/withdrawalHistory')
      },
      {
        path: 'withdrawal-details/:id',
        name: 'WithdrawalDetails',
        component: () => import('@/views/account/invitation/withdrawalDetails')
      },
      {
        path: 'withdraw',
        name: 'Withdraw',
        component: () => import('@/views/account/withdraw'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'add-withdraw/:type',
        name: 'AddWithdraw',
        component: () => import('@/views/account/withdraw/add'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'convert-coupon',
        name: 'ConvertCoupon',
        component: () => import('@/views/account/withdraw/convert'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'my-messages',
        name: 'MyMessages',
        component: () => import('@/views/account/myMessages'),
        meta: { activeMenu: '/account' }
      },
      {
        path: 'messages-detail/:id',
        name: 'MessagesDetail',
        component: () => import('@/views/account/myMessages/detail'),
        meta: { activeMenu: '/account' }
      }
    ]
  },
  {
    path: '/account/add-identity/OTHER',
    name: 'IdentityOther',
    component: () => import('@/views/account/supplementaryMaterials/other')
  },
  {
    path: '/kyc',
    name: 'KYC',
    component: () => import('@/views/kyc')
  },
  {
    path: '/kyc-edit',
    name: 'KycEdit',
    component: () => import('@/views/kyc/edit')
  },
  {
    path: '/signUp',
    name: 'SignUp',
    component: () => import('@/views/signUp'),
    redirect: '/signUp/login',
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/signUp/login')
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/signUp/register')
      }
    ]
  },
  {
    path: '/pay-finally/:orderSign',
    name: 'PayFinally',
    component: () => import('@/views/transaction/payFinally')
  },
  {
    path: '/successful',
    name: 'Successful',
    component: () => import('@/views/signUp/successful')
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => import('@/views/callback')
  },
  {
    path: '/weChatLogin',
    name: 'WeChatLogin',
    component: () => import('@/views/callback/login')
  },
  {
    path: '/404',
    component: Layout,
    children: [
      {
        path: '',
        name: 'ErrorPage',
        component: () => import('@/views/errorPage/404')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  }
]
const isDev = process.env.NODE_ENV === 'development'
const base = isDev ? '/' : '/client'
const router = createRouter({
  history: createWebHistory(base),
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes
})

export default router
