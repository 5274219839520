import { isWX } from '@/utils'
import axios from '@/utils/request'

// 客户收款账号详情
export function collectionAcctDetail(params) {
  return axios({
    url: 'api/v1/CollectionAcctDetail',
    method: 'get',
    params
  })
}
// 客户汇款额度
export function clientQuota(params) {
  return axios({
    url: 'api/v1/ClientQuota',
    method: 'get',
    params
  })
}
// 置换优惠券
export function withdrawToCoupon(data) {
  return axios({
    url: 'api/v1/WithdrawToCoupon',
    method: 'POST',
    data
  })
}
// 客户邀请奖励明细列表
export function inviteRewardFunds(params) {
  return axios({
    url: 'api/v1/InviteRewardFunds',
    method: 'get',
    params
  })
}
// 邀请数量
export function inviteTotal(params) {
  return axios({
    url: 'api/v1/InviteTotal',
    method: 'get',
    params
  })
}
// 检查是否需要认证
export function checkWeChat(params) {
  if (!isWX()) return Promise.resolve()
  return axios({
    url: 'api/v1/CheckWeChat',
    method: 'get',
    params
  })
}
// 授权
export function oAuthCallback(params) {
  return axios({
    url: 'api/v1/OAuthCallback',
    method: 'get',
    params
  })
}
// 编辑准入信息
export function admissionAuth(data) {
  return axios({
    url: 'api/v1/AdmissionAuth',
    method: 'put',
    data
  })
}
// 税单批量提交
export function taxProofBatch(data) {
  return axios({
    url: 'api/v1/TaxProofBatch',
    method: 'Post',
    data
  })
}
// 消息列表
export function clientPm(params) {
  return axios({
    url: 'api/v1/ClientPm',
    method: 'get',
    params
  })
}
// 删除客户消息
export function deleteClientPm(data) {
  return axios({
    url: 'api/v1/ClientPm',
    method: 'delete',
    data
  })
}
// 消息详情
export function clientPmDetail(params) {
  return axios({
    url: 'api/v1/ClientPmDetail',
    method: 'get',
    params
  })
}
// 补充旧身份证件信息
export function realOldCert(data) {
  return axios({
    url: 'api/v1/RealOldCert',
    method: 'Post',
    data
  })
}
// 邀请
export function invite(data) {
  return axios({
    url: 'api/v1/Invite',
    method: 'put',
    data
  })
}
// 税单使用列表
export function taxProofUsed(params) {
  return axios({
    url: 'api/v1/TaxProofUsed',
    method: 'get',
    params
  })
}
// 文章列表
export function article(params) {
  return axios({
    url: 'api/v1/RemitArticle',
    method: 'get',
    params
  })
}
// 文章分类列表
export function articleCategory(params) {
  return axios({
    url: 'api/v1/RemitArticleCategory',
    method: 'get',
    params
  })
}
// 优惠券列表
export function coupon(params) {
  return axios({
    url: 'api/v1/RemitCoupon',
    method: 'get',
    params
  })
}
// 个人身份认证信息
export function personalIdentity(params) {
  return axios({
    url: 'api/v1/PersonalIdentity',
    method: 'get',
    params
  })
}
// 补充个人身份认证信息
export function editPersonalIdentity(data) {
  return axios({
    url: 'api/v1/PersonalIdentity',
    method: 'put',
    data
  })
}
// 提交个人身份认证信息
export function sendPersonalIdentity(data) {
  return axios({
    url: 'api/v1/PersonalIdentity',
    method: 'Post',
    data
  })
}
// 客户邀请码换优惠劵
export function clientCouponExchange(data) {
  return axios({
    url: 'api/v1/ClientCouponExchange',
    method: 'Post',
    data
  })
}
// 兑换码列表
export function remitVoucher(params) {
  return axios({
    url: 'api/v1/RemitVoucher',
    method: 'get',
    params
  })
}
// 优惠券领取
export function remitCoupon(data) {
  return axios({
    url: 'api/v1/RemitCoupon',
    method: 'Post',
    data
  })
}
// 客户推广邀请记录列表
export function inviteList(params) {
  return axios({
    url: 'api/v1/Invite',
    method: 'get',
    params
  })
}
// 客户银行账号列表
export function bankAccount(params) {
  return axios({
    url: 'api/v1/BankAccount',
    method: 'get',
    params
  })
}
// 银行账户创建
export function addBankAccount(data) {
  return axios({
    url: 'api/v1/BankAccount',
    method: 'post',
    data
  })
}
// 提现
export function withdrawOrder(data) {
  return axios({
    url: 'api/v1/WithdrawOrder',
    method: 'Post',
    data
  })
}
// 优惠活动列表
export function promotionActivity(params) {
  return axios({
    url: 'api/v1/PromotionActivity',
    method: 'get',
    params
  })
}
// 客户提现订单列表
export function withdrawList(params) {
  return axios({
    url: 'api/v1/WithdrawOrder',
    method: 'get',
    params
  })
}
// 客户提现订单详情
export function withdrawOrderDetail(params) {
  return axios({
    url: 'api/v1/WithdrawOrderDetail',
    method: 'get',
    params
  })
}
// 文章
export function articleDetail(params) {
  return axios({
    url: 'api/v1/RemitArticleDetail',
    method: 'get',
    params
  })
}
// 存量客户协议弹窗
export function clientTermDetail(params) {
  return axios({
    url: 'api/v1/ClientTermDetail',
    method: 'get',
    params
  })
}
// 确认查看协议
export function clientTerm(data) {
  return axios({
    url: 'api/v1/ClientTerm',
    method: 'Post',
    data
  })
}
// acs变更记录
export function acsChangeRecord(params) {
  return axios({
    url: 'api/v1/AcsChangeRecord',
    method: 'get',
    params
  })
}
// 新增客户反馈
export function clientFeedback(data) {
  return axios({
    url: 'api/v1/ClientFeedback',
    method: 'Post',
    data
  })
}
// 上传税单是否成功
export function taxProofUnFinish(params) {
  return axios({
    url: 'api/v1/TaxProofUnFinish',
    method: 'get',
    params
  })
}
// 全部已读
export function clientPmClean(data) {
  return axios({
    url: 'api/v1/ClientPmClean',
    method: 'post',
    data
  })
}
