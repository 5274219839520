import Cookies from 'vue-cookies'

const TokenKey = 'skyee_remit_token'

export const remitDialog = 'skyee_remit_dialog'
export const partRemitDialog = 'skyee_remit_part_dialog'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, '1d')
}

export function removeToken() {
  Cookies.remove(TokenKey, '/signUp')
  Cookies.remove(remitDialog)
  Cookies.remove(partRemitDialog)
  Cookies.remove(TokenKey)
}
