<template>
  <main class="app-main">
    <AppEmpty v-if="!status && showEmpty" :title="$route.name" />
    <RouterView v-else v-slot="{ Component }">
      <KeepAlive :include="appStore._cachedViews">
        <component :is="Component" />
      </KeepAlive>
    </RouterView>
  </main>
</template>

<script setup>
import AppEmpty from '@/components/empty'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useUser } from '@/store/useUser'
import { useApp } from '@/store/useApp'

const userStore = useUser()
const appStore = useApp()
const route = useRoute()
const status = computed(() => userStore._profile.status)
const emptyList = [
  { name: 'Recipients', label: '收款人' },
  { name: 'Transaction', label: '汇款记录' }
]

const showEmpty = computed(() => emptyList.find(item => item.name === route.name))

</script>

<script>
export default { name: 'AppMain' }
</script>

<style lang='scss' scoped>
.app-main{
  // tabbar 高度
  padding-bottom: calc(.98rem + env(safe-area-inset-bottom));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
