<template>
  <van-tabs
    class="app-tabs"
    :active="active"
    animated
    :sticky="sticky"
    :offset-top="offsetTop"
    @change="handleTag"
  >
    <van-tab
      v-for="item in data"
      :key="item.value"
      :name="item.value"
      :title="item.label"
    >
      <slot />
    </van-tab>
  </van-tabs>
</template>

<script setup>
import { toRefs } from 'vue'
const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  active: {
    type: [String, Number],
    default: ''
  },
  sticky: {
    type: Boolean,
    default: true
  },
  offsetTop: {
    type: [Number, String],
    default: 0
  }
})
const { active } = toRefs(props)

const emits = defineEmits(['changeTag'])
function handleTag(val) {
  if (val === props.active) return
  emits('changeTag', val)
}
</script>

<script>
export default { name: 'AppTab' }
</script>

<style lang='scss'>
.van-tabs{
  .van-tabs__wrap{
    transition: all .18s ease-in-out;
    position: relative;
    &:after {
      position: absolute;
      box-sizing: border-box;
      content: " ";
      pointer-events: none;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      border: 0 solid #EBEBEB;
      transform: scale(.5);
      border-bottom-width: 1px;
    }
    .van-tabs__nav{
      background: transparent;
      padding-bottom: .26rem;
    }
   .van-tabs__line{
    background: $blue;
    width: .6rem;
    height: .08rem;
   }
  }
  .van-tab{
    color: $color99;
    font-family: 'Arial-Bold';
    font-size: .32rem;
    line-height: .36rem;
    &.van-tab--active{
      color: $color33;
    }
  }
  .van-sticky--fixed{
    .van-tabs__wrap{
      background: $baseBg;
    }
  }
}
</style>
