<template>
  <AppDialog
    :visible="visible"
    :confirm-button-text="false"
    title=""
    @update:visible="close"
  >
    <div class="content">
      <div class="t w600">Follow SkyRemit on Wechat</div>
      <div class="img-box img-contain">
        <img :src="url" alt="">
      </div>
      <div class="desc">
        Follow for updates and quick access to remittance.
      </div>
    </div>
  </AppDialog>
</template>

<script setup>
import { toRefs } from 'vue'
import { baseUrl } from '@/utils/request'
import { setFocus } from '@/utils/focus'
const url = baseUrl + 'static/app/images/qrcode.jpg'

const props = defineProps({
  visible: Boolean
})
const { visible } = toRefs(props)
const emit = defineEmits(['update:visible'])
function close() {
  setFocus()
  emit('update:visible', false)
}
</script>

<script>
export default { name: 'AppCodeDialog' }
</script>

<style lang='scss' scoped>
.content{
  padding-top: .6rem;
  text-align: center;
  .t{
    font-size: .32rem;
    color: $color33;
  }
  .img-box{
    display: inline-block;
    width: 3rem;
    height: 3rem;
  }
  .desc{
    margin-top: .1rem;
    font-size: .24rem;
    color: $color66;
  }
}
</style>
