<template>
  <van-form
    ref="formRef"
    class="AppForm"
    scroll-to-error
    show-error
    v-bind="$attrs"
    :submit-on-enter="false"
    @submit="onSubmit"
  >
    <div class="form-inner">
      <van-row gutter="10" :align="rowAlign">
        <template v-for="item in _formItems" :key="item.prop">
          <slot :name="item.itemAttrs.topSlotName" />
          <van-col v-if="item._ifRender" :span="item.span || 24">
            <div class="form-item">
              <div v-if="item.itemAttrs.label" class="label">
                <label>
                  {{ item.itemAttrs.label }}
                  <span v-if="item.itemAttrs.optional && !item.itemAttrs.noOption" class="label-optional">(optional)</span>
                </label>
                <AppPopover
                  v-if="item.itemAttrs.tooltip"
                  :content="item.itemAttrs.tooltip"
                />
              </div>
              <AppAlert
                v-if="item.itemAttrs.alertAttrs"
                :config="item.itemAttrs.alertAttrs"
                class="mb30"
              />
              <slot
                :name="item.propKey? `${item.propKey}_${item.prop}` : item.prop"
              >
                <component
                  :is="item.tag"
                  v-if="item.propKey"
                  v-model="dataForm[item.propKey][item.prop]"
                  :name="`${item.propKey}_${item.prop}`"
                  v-bind="item.attrs"
                  :disabled="disabled || item.attrs.disabled"
                />
                <component
                  :is="item.tag"
                  v-else
                  v-model="dataForm[item.prop]"
                  :name="item.prop"
                  v-bind="item.attrs"
                  :disabled="disabled || item.attrs.disabled"
                />
              </slot>
              <slot :name="item.itemAttrs.slotName" />
              <!-- v-if="item.attrs.errorSlotName"  -->
            </div>
          </van-col>
        </template>
      </van-row>
    </div>
    <slot name="append" />
    <slot name="submit">
      <div class="button-group">
        <AppButton v-if="cancelText" @click="handleCancel">
          {{ cancelText }}
        </AppButton>
        <AppButton
          v-if="submitText"
          type="primary"
          :disabled="subDisabled"
          :loading="sLoading"
          @click="handleSubmit"
        >
          {{ submitText }}
        </AppButton>
      </div>
    </slot>
  </van-form>
</template>

<script setup>
import { basic } from './config'
import { computed, toRefs, ref } from 'vue'
import { useBlurRules, useChangeRules } from '@/utils/rules'

const props = defineProps({
  formConfig: {
    type: Object,
    default: () => {}
  },
  dataForm: {
    type: Object,
    default: () => {}
  },
  cancelText: {
    type: [String, Boolean],
    default: ''
  },
  submitText: {
    type: [String, Boolean],
    default: ''
  },
  rowAlign: {
    type: String,
    default: 'top'
  },
  sLoading: {
    type: Boolean,
    default: false
  },
  subDisabled: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['handleCancel', 'submit'])
const { dataForm, formConfig } = toRefs(props)
const _formItems = computed(() => formConfig.value.map(item => computeFormItem(item, dataForm.value)))
/**
 * @description: 计算form-item
 * @param {Object} formItem
 * @param {Object} dataForm
 * @return {Object}
 */
function computeFormItem(formItem, dataForm) {
  const item = { ...formItem }
  // 表单控件的类型
  const tag = item.tag || 'input'
  // 对应到组件映射表
  const basicItem = basic[tag]
  item.tag = basicItem.component
  // 继承基类的itemAttrs属性
  item.itemAttrs = Object.assign({ }, basicItem.itemAttrs, item.itemAttrs)
  // 继承基类的attrs属性
  item.attrs = Object.assign({}, basicItem.attrs, item.attrs)
  // 获取动态 itemAttrs
  if (item.getItemAttrs) {
    item.itemAttrs = Object.assign(
      item.itemAttrs,
      item.getItemAttrs(dataForm)
    )
  }
  const { label } = item?.itemAttrs || {}
  if (basicItem.rulesRrigger === 'onBlur' && !item.attrs.rules) {
    item.attrs.rules = useBlurRules(label)
  }
  if (basicItem.rulesRrigger === 'onChange' && !item.attrs.rules) {
    item.attrs.rules = useChangeRules(label)
  }
  // 获取动态 attrs
  if (item.getAttrs) {
    item.attrs = Object.assign(item.attrs, item.getAttrs(dataForm))
  }
  // 获取动态 表单验证
  if (item.itemAttrs.optional) {
    item.attrs.rules = [{ required: false }]
  }
  // 获取动态 表单验证
  if (item.getRules) {
    item.attrs.rules = item.getRules(dataForm)
  }
  // 条件渲染
  item._ifRender = item.ifRender ? item.ifRender(dataForm) : true
  // form-item 配置
  return item
}
function handleCancel() {
  emit('handleCancel')
}
function onSubmit(values) {
  emit('submit', values)
}
function handleSubmit() {
  if (props.disabled) {
    onSubmit()
  } else {
    formRef.value.submit()
  }
}
const formRef = ref(null)
function validate(prop) {
  return formRef.value.validate(prop)
}
function resetValidation(prop) {
  formRef.value.resetValidation(prop)
}
function submit() {
  formRef.value.submit()
}
defineExpose({
  validate,
  resetValidation,
  submit
})
</script>

<script>
export default { name: 'AppForm' }
</script>

<style lang='scss' scoped>
.button-group{
  padding-top: .4rem;
}
</style>
