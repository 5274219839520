<template>
  <div class="app-alert">
    <p
      v-for="(item,index) in config"
      :key="index"
      class="item f24"
      :class="{
        't':item.isT,
        'mb':item.isMb,
        'notDot':item.notDot,
        'black':item.isBlack,
      }"
    >
      <slot :name="item.slotName">
        {{ item.txt }}
      </slot>
    </p>
  </div>
</template>

<script setup>
defineProps({
  config: {
    type: Array,
    default: () => []
  }
})

</script>

<script>
export default { name: 'AppAlert' }
</script>

<style scoped lang='scss'>
.app-alert{
  background: #ECECEC;
  border-radius: .2rem;
  padding: .3rem .3rem .2rem;
  .item{
    line-height: .28rem;
    color: $color99;
    position: relative;
    margin-bottom: .1rem;
    font-family: 'Arial-Regular';
    padding-left: .16rem;
    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: .1rem;
      background: $color99;
      width: .08rem;
      height: .08rem;
      border-radius: 50%;
    }
    &.notDot{
      padding-left: 0;
      &::before{
        display: none;
      }
    }
  }
  .black{
    color: $color33;
  }
  .t{
    color: $color33;
    font-family: 'Arial-Bold';
  }
  .black,
  .t{
    &::before{
      background: $color33;
    }
  }
  .mb{
    margin-bottom: .4rem;
  }
}
</style>
