import axios from '@/utils/request'

export function realAuth(data) {
  return axios({
    url: 'api/v1/RealAuth',
    method: 'post',
    data
  })
}
export function getRealAuth(params) {
  return axios({
    url: 'api/v1/RealAuth',
    method: 'get',
    params
  })
}
export function businessOpenDetail(params) {
  return axios({
    url: 'api/v1/BusinessOpenDetail',
    method: 'get',
    params
  })
}
export function editRealAuth(data) {
  return axios({
    url: 'api/v1/RealAuth',
    method: 'put',
    data
  })
}
export function checkCertNo(params) {
  return axios({
    url: 'api/v1/CheckCertNo',
    method: 'get',
    params
  })
}
// 税单文件解析
export function taxProofComplete(params) {
  return axios({
    url: 'api/v1/TaxProofComplete',
    method: 'get',
    params
  })
}
