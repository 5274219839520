import { baseUrl } from '@/utils/request'
import { getToken } from '@/utils/auth'
import router from '@/router'
import { checkWeChat } from '@api/account'
import { useUser } from '@/store/useUser'
import { useApp } from '@/store/useApp'

function hasPermission(to, userStore) {
  // ACS驳回
  if (userStore._clientAuth.acsStatus === 'REFUSE' && to.path !== '/account/security') {
    return '/account/security'
  }
}

// 未登录白名单
export const whiteList = ['Login', 'Register', 'TermsPrivacy', 'Recipients', 'Transaction', 'Send', 'Account', 'WeChatLogin']

// 登录黑名单页面
const loginBlack = ['Login', 'Register']
router.beforeEach(async(to, from) => {
  const userStore = useUser()
  const appStore = useApp()
  if (getToken()) {
    if (loginBlack.indexOf(to.name) !== -1) {
      return '/'
    } else {
      if (userStore._profile.status !== undefined) {
        // kyc驳回
        return true
      } else {
        try {
          const res = await Promise.all([
            checkWeChat(),
            userStore.getClientBasis(),
            userStore.getProfile(),
            appStore.getClientPmTotal(),
            appStore.getClientSetting()
          ])
          const [authRes] = res
          if (authRes?.showButton && +userStore.clientBasis.bindWechat === 0 && !to.path.includes('callback')) {
            window.location.href = `${baseUrl}api/v1/OAuth?targetUrl=${to.path}`
            return
          }
          if (hasPermission(to, userStore)) {
            return hasPermission(to, userStore)
          } else {
            return true
          }
        } catch {
          await userStore.resetToken()
          return `/signUp/login`
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.name) !== -1) {
      return true
    } else {
      return !to.path.includes('404') ? `/signUp/login?redirect=${to.path}` : '/signUp/login'
    }
  }
})
