<template>
  <div class="list-empty" :class="{'mini':isMini}">
    <div class="img-box img-contain">
      <img :src="imgUrl" alt="">
    </div>
    <p v-if="tips" class="tips f28">{{ tips }}</p>
    <slot />
  </div>
</template>

<script setup>
defineProps({
  imgUrl: String,
  tips: String,
  isMini: {
    type: Boolean,
    default: false
  }
})

</script>

<script>
export default { name: 'AppListEmpty' }
</script>

<style scoped lang='scss'>
.list-empty{
  padding: 1rem 0;
  text-align: center;
  .img-box{
    display: inline-block;
    width: 5rem;
  }
  .tips{
    color: $color99;
  }
  &.mini{
    padding: .2rem 0 0;
    .img-box{
      width: 2.6rem;
    }
    .tips{
      font-size: .24rem;
    }
  }
}
</style>
