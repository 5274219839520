<template>
  <div id="app">
    <RouterView v-slot="{ Component }">
      <component :is="Component" />
    </RouterView>
    <AppFloatService />
  </div>
</template>

<script setup>
import { baseUrl } from '@/utils/request'
import { weChatJs } from '@api/base'
import { onMounted } from 'vue'
import { isWX } from '@/utils'
import wx from 'wx'

function getWeChatJs() {
  if (!isWX()) return
  const params = {
    url: window.location.href
  }
  weChatJs(params).then(res => {
    wx.config({
      ...res.content
    })
    wx.ready(function () {
      wx.updateAppMessageShareData({
        title: 'SkyRemit', // 分享标题
        desc: 'Help expats to send your earnings to homeland in a fast, secure, convenient way!', // 分享描述
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: baseUrl + 'static/app/images/SkyRemitShare.png' // 分享图标
      })
    })
  }).catch(() => {
    console.log('微信分享配置失败')
  })
}
onMounted(() => {
  getWeChatJs()
})
</script>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss" scoped>
#app{
  max-width: 7.5rem;
  margin: 0 auto;
}
</style>
